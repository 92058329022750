import React from 'react'
import './Modal.css'
import ReactModal from "react-modal";
import Lottie from "lottie-react";
// import Sucess from '../../Assets/Lottie/Sucess.json'
// import Payment from '../../Assets/Lottie/paymentDone.json'
// import Warning from '../../Assets/Lottie/Error.json'
// import Delete from '../../Assets/Lottie/Delete.json'
import { AiOutlineClose } from "react-icons/ai";
import quikLogo from '../../Assets/QuikLogo.png'
import { QRCode } from 'react-qrcode-logo';
const Modal = (props) => {
    const qrCodeRef = React.createRef();

    const customStyles = {
        content: {
            width: window.screen.width>1023?"25%":"80%",
            height: "auto",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: "4px",
            overflow: 'hidden',
            padding: '5px 0 0 0'

        },
    };
    const style = {
        width: "30%"
    };
    return (
        <ReactModal ariaHideApp={false}
            isOpen={props.isOpen}
            contentLabel="Minimal Modal Example"
            className={props.class}
            overlayClassName="Overlay"
            onRequestClose={props.onRequestClose}
            style={customStyles}
        >
            <div className='Close_Button'>
                <button type="button" onClick={props.onRequestClose} className="closeIcon">
                    X
                </button>
            </div>
            {props.QrShow ?
                <div className="MsgDiv">
                    <small className='hintText'>{props.hintText}</small>
                    <button style={{ display: props.okBtnHide == true ? "none" : "", width: props?.btnWidth ? props?.btnWidth : "80px" }} onClick={props?.okBtnFunCondition ? props.modelOkBtnFun : props.onRequestClose} className="ModalBtn"> {props.modalBtnMessage ? props.modalBtnMessage : "Ok"} </button>
                </div>
                :
                <div className="qrDivModal" style={{ marginBottom: '20px' }}>
                <QRCode 
                  ref={qrCodeRef} 
                  value={props?.generatedLink} 
                  size={150} 
                  removeQrCodeBehindLogo={true}
                  logoPadding={5}
                  logoPaddingStyle ={"circle"} 
                  qrStyle={"dots"} 
                  logoImage={quikLogo}  
                  logoWidth={30} 
                  logoHeight={30} 
                  eyeRadius={[
                    { outer: [10, 10, 0, 10], inner: [0, 10, 10, 10] }, // TL
                    { outer: [10, 10, 10, 0], inner: [10, 0, 10, 10] }, // TR
                    { outer: [10, 0, 10, 10], inner: [10, 10, 0, 10] }  // BL
                  ]}
                  eyeColor="#01774a" // Change eye color if needed
                />
                <span className='scanMessage'>Scan QR code to view the resume</span>
                {/* <button onClick={()=>{handleDownload()}}>Download QR Code</button> */}
              </div>
            }
        </ReactModal>
    )
}
export default Modal;
