import React from 'react'
import addIcon from '../../Assets/AddIcon.svg'
import './AddField.css'
const AddField = ({
    addFieldWrapper,
    fieldTxt,
    onClick
}) => {
  return (
    <div className={`${addFieldWrapper} addFieldButtonDiv`} onClick={onClick}>
        <span><img src={addIcon} alt=''/> {fieldTxt}</span>
    </div>
  )
}

export default AddField