import CryptoJS from 'crypto-js';
export const handleEncrypt = (inputText) => {
  // key for Encryption and Decryption
    const key = CryptoJS.enc.Utf8.parse("123456$#@$^@1ERF");
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(inputText?.toString()), key,
    {
      keySize: 128 / 8,
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  };

  
 export const handleDecrypt = (encryptedText) => {
    // key for Encryption and Decryption
    const key = CryptoJS.enc.Utf8.parse('123456$#@$^@1ERF');
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
      keySize: 128 / 8,
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };