export const initialModalState = {
    isRequired: false,
    generatedLinks: '',
    hintText: '',
    class: '',
    successMsg: false,
    wrongMsg: false,
    deleteMsg: false,
    btnMessage: ""
}
export const getQRCodeModalObject = (generatedLink, showOkBtn, okBtnFun = '', btnWidth = '80px') => {
    return {
        isRequired: true,
        generatedLinks: generatedLink,
        QrShow : true,
        hintText: "",
        class: "",
        successMsg: true,
        wrongMsg: false,
        deleteMsg: false,
        okBtnFunCondition: showOkBtn,
        okBtnHide: false,
        btnMessage: "Ok",
        btnWidth: btnWidth || "80px",
        okBtnFunction: okBtnFun || '',
    };
};

export const getWrongModalObject = (message, showOkBtn, okBtnFun = '', btnWidth = '80px') => {
    return {
        isRequired: true,
        message: message,
        hintText: "",
        class: "",
        successMsg: false,
        wrongMsg: true,
        deleteMsg: false,
        okBtnFunCondition: showOkBtn,
        okBtnHide: false,
        btnMessage: "Ok",
        btnWidth: "80px",
        okBtnFunction: okBtnFun || '',
    };
};

export const getDeleteModalObject = (message, showOkBtn, okBtnFun = '', btnWidth = '80px') => {
    return {
        isRequired: true,
        message: message,
        hintText: "",
        class: "",
        successMsg: false,
        wrongMsg: false,
        deleteMsg: true,
        okBtnFunCondition: showOkBtn,
        okBtnHide: false,
        btnMessage: "Ok",
        btnWidth: "80px",
        okBtnFunction: okBtnFun || '',
    };
};