import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import logoImageUrl from "../../Assets/QuickHireLogo.svg";
import Modal from "react-modal";
import Lottie from "lottie-react";
import createResume from "../../Assets/createResumeIcon.svg";
import viewResume from "../../Assets/viewResume.svg";
import wrongTick from "../../Assets/wrongTick.svg";
import rightTick from "../../Assets/rightTick.svg";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="DashboardOuterDiv" style={{ backgroundColor: "#FFFF"}}>
        <div className="DashboardFormDiv" style={{height:"auto"}}>
          <div className="hintTextWithLogo">
            <img onClick={()=>window.open('https://www.quikhire.ai','_blank')} src={logoImageUrl ?? ''} style={{cursor:"default"}} />
            <div className="titleTextResume">
            Get your dream job with the professional resume builder
            </div>
          </div>
        <div className="navigationButtonsDiv">
        <button id="createResumeBtn" onClick={()=>navigate('/Main',{state:{dashboardNavigate:true}})}> <img className="createResumeBtn" src={createResume} alt="" /> Create Resume</button>
        <button id="viewResumeBtn" onClick={()=>{navigate('/login',{state:{dashboardNavigate:true}})}}> <img className="createResumeBtn" src={viewResume} alt="" /> View Resume</button>
        </div>
        </div>
        <div className="DashboardImgDiv" style={{height:"auto"}}>
                <div className="createResumeOffers">
                    {/* <span className="costTag">Free</span> */}
                    <div className="offerListDiv">
                        <h4 className="offerHeading">Create Resume</h4>
                        <ul>
                            <li><img src={rightTick} alt="" /> Create Resume</li>
                            <li><img src={rightTick} alt="" /> Upload Audio File</li>
                            <li><img src={rightTick} alt="" /> Resume URL</li>
                            <li><img src={rightTick} alt="" /> Customized URL</li>
                            {/* <li><img src={wrongTick} alt="" />Edit Resume</li>
                            <li><img src={wrongTick} alt="" />Download Resume</li>
                            <li><img src={wrongTick} alt="" />QR Code</li> */}
                        </ul>
                    </div>
                </div>
                <div className="viewResumeOffers">
                {/* <span className="costTag">Free</span> */}
                    <div className="offerListDiv">
                        <h4 className="offerHeading">View Resume</h4>
                        <ul>
                            <li><img src={rightTick} alt="" /> Create Resume</li>
                            <li><img src={rightTick} alt="" /> Upload Audio File</li>
                            <li><img src={rightTick} alt="" /> Resume URL</li>
                            <li><img src={rightTick} alt="" />Edit Resume</li>
                            <li><img src={rightTick} alt="" />Download Resume</li>
                            <li><img src={rightTick} alt="" />QR Code</li>
                            <li><img src={rightTick} alt="" /> Customized URL</li>
                        </ul>
                    </div>
                </div>
          </div>
        <div className="poweredByDiv">ShareResume.co powered by&nbsp;<span onClick={()=>window.open('https://www.quikhire.ai','_blank')}>QuikHire.ai</span></div>
      </div>
    </>
  );
};

export default LandingPage;
