import React from 'react'
import InputText from '../Components/InputText/InputText'
import TextArea from '../Components/TextArea/TextArea'
import AddField from '../Components/AddField/AddField'
import PersonalDetails from '../Components/PersonalDetails/PersonalDetails'
import WorkExperience from '../Components/WorkExperience/WorkExperience'
import EducationalDetails from '../Components/EducationalDetails/EducationalDetails'
import CertificationDetails from '../Components/CertificationDetails/CertificationDetails'
import SelectField from '../Components/SelectField/SelectField'
import ClosingDetails from '../Components/ClosingDetails/ClosingDetails'
import DatePickerField from '../Components/DatePickerField/DatePickerField'
import MobileResumePreview from '../Components/MobileResumePreview/MobileResumePreview'
import ShowGeneratedResume from '../Components/ShowGeneratedResume/ShowGeneratedResume'
import ToggleSwitch from '../Components/ToggleSwitch/ToggleSwitch'
import WebNavigation from '../WebNavigation/WebNavigation'
import MobileNumberField from '../Components/MobileNumberField/MobileNumberField'
import Modal from '../Screens/Modal/Modal'
import Login from '../Login/Login'
import InputFile from '../Components/InputFile/InputFile'
import InterShipDetails from '../Components/InterShipDetails/InterShipDetails'
import LoaderModal from '../Screens/LoaderModal/LoaderModal'


export const ConstantScreen = {
    InputText,
    InputFile,
    TextArea,
    AddField,
    PersonalDetails,
    WorkExperience,
    MobileNumberField,
    EducationalDetails,
    CertificationDetails,
    SelectField,
    ClosingDetails,
    DatePickerField,
    MobileResumePreview,
    ShowGeneratedResume,
    ToggleSwitch,
    WebNavigation,
    Modal,
    Login, 
    InterShipDetails,
    LoaderModal
}

