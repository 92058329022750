import React, { useState } from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label,onChange,inputValue }) => {
  const [isChecked, setIsChecked] = useState(inputValue);
  const handleChange = (e) => {
    setIsChecked(!isChecked);
    onChange(e)
    };

  return (
    <div className="containerToggle">
        <label className="toggleLabel" htmlFor={label}>{label}</label>
      <div className="toggle-switch">
        <input
          type="checkbox"
          
          className="checkbox"
          name={label}
          id={label}
          checked={isChecked}
          onChange={(e)=>{handleChange(e)}}
        />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch">
            {isChecked ? "Experience" : "Fresher"}
          </span>
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
