import React, { useEffect, useState } from 'react'
import { ConstantScreen } from '../../ConstantsScreen/ConstantScreen'
import './ClosingDetails.css'
import { Url } from '../../Constants/ApiUrlConstants'
import { getAPICall } from '../../APIMethods/APIMethods'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Lottie from 'lottie-react';
import Error from '../../Assets/Opps.json'
import Success from '../../Assets/Success.json'
import deleteIcon from '../../Assets/delete.png'

const ClosingDetails = ({ data, setData, languageSelectedValues,setClosingErrors,closingErrors,setSubmitResume }) => {
  const [allLanguages, setAllLanguages] = useState([])
  const [cntryCodeOptions,setCntryCodeOptions] = useState([])
  const [selectedCntryCode,setSelectedCntryCode] = useState(null)
  const [errModal,setErrModal] = useState(false)
  const [successModal,setsuccessModal] = useState(false)
  const [modalMsg,setModalMsg] = useState("")
  const [selectedCntryCodes, setSelectedCntryCodes] = useState(Array(data?.referrals?.length).fill({}));

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          minWidth:window.screen.width>1023?"30%":"70%",
          maxWidth:window.screen.width>1023?"50%":"80%",
          borderRadius:"10px",
          padding:"10px"
        },
      };
  const handleAddReferalDetails = () => {
    if(data.referrals.length < 3){
      setData({
        ...data,
        referrals: [
          ...data.referrals,
          { referrerName: null, mobileNumber: null, email: null }
        ]
      });
    }else{
      // toast.warning("You can add upto 3 referrals");
      setModalMsg("You can add upto 3 referrals")
      setErrModal(true)
  }
  };
  const handleInputChange = (e, field, index) => {
    setSubmitResume(false)
    setData((prevData) => {
      if (field === "languages") {
        const updatedLanguages = e.map((item) => ({
          languageId: item.value,
          languageName: item.label,
        }));
        return { ...prevData, languages: updatedLanguages };
      } else if (["referrerName", "mobileNumber", "email"].includes(field)) {
        let updatedReferrals = prevData?.referrals.map((referral, i) => {
          if (i !== index) return referral;
          
          const newValue = e?.target?.value;
          
          if (field === "mobileNumber") {
            const sanitizedMobileNumber = newValue.replace(/\D/g, '').trim()
            if (sanitizedMobileNumber.length <= 10) {
              return { ...referral, [field]: sanitizedMobileNumber,['countryCode'] : selectedCntryCode};
            }
          } else if (field === "referrerName") {
            if (newValue != '') {
              return { ...referral, [field]: newValue };
            }else{
              return { ...referral, [field]: null };
            }
          } else if (field === "email") {
            return { ...referral, [field]: newValue };
          }
          
          return referral;
        });
        
        return { ...prevData, referrals: updatedReferrals };
      } else {
        return { ...prevData, [field]: e?.target?.value };
      }
    });
  
    const errorMsg = validateField(field, e?.target?.value);
    setClosingErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMsg
    }));
  };
  

  const validateField = (field, value) => {
    let errorMsg = '';
    
    switch (field) {
      case 'hireMeQuestion': 
      if (!value.trim() || !/^[^\s][\s\S]*$/.test(value)) {
        errorMsg = 'Why Should You Hire Me? is required and must start with a letter.';
      }
        break;
        case 'summary': 
          if (!value.trim() || !/^[A-Za-z]/.test(value)) {
            errorMsg = `Professional Summary is required and must start with a letter.`;
          }
          break;
      default:
        break;
    }
    
    return errorMsg;
  };
  const handleSelectCntryCode = (index, value) => {
    const newSelectedCntryCodes = [...selectedCntryCodes];
    newSelectedCntryCodes[index] = value;
    setSelectedCntryCodes(newSelectedCntryCodes);
  
    setData((prevData) => {
      const updatedReferrals = prevData?.referrals.map((referral, i) => {
        if (i !== index) return referral;
        return { ...referral, countryCode: value };
      });
      return { ...prevData, referrals: updatedReferrals };
    });
  };

  useEffect(() => {
    getAPICall(Url.getAllLanguages)
      .then((res) => {
        const allLanguagesArray = (res.DATA).map((item) => ({
          value: item?.languageId,
          label: item?.languageName
        }))
        setAllLanguages(allLanguagesArray)
      })

      getAPICall(Url?.getCountryCode)
      .then((res) => {
        setCntryCodeOptions(res?.DATA)
        setSelectedCntryCode(res?.DATA[0])
      })
  }, [])

  const handleRemoveReferral = (indexToRemove) => {
    // if (data.referrals.length > 1) {
      if (indexToRemove >= 0 && indexToRemove < data.referrals.length) {
        const newreferrals = data.referrals.slice();
        newreferrals.splice(indexToRemove, 1);
        setData({ ...data, referrals: newreferrals });
      } else {
        console.error('Invalid index for removing Referral Detail:', indexToRemove);
      }
    // } else {
    //   // toast.warning("Please add atleast one Referral Detail before removing.")
    //   setModalMsg("Please add atleast one Referral Detail before removing.")
    //   setErrModal(true)
    // }
  };
  return (
    <div className='closingInformation'>
      {/* <div className="formLabel">
        <span className="fieldLabel">Language</span>
      </div> */}
      <ConstantScreen.SelectField
        labelTxt="Languages Known"
        inputWrapperStyle="zipCodeWrapper"
        selectOptions={allLanguages}
        errMsg={closingErrors?.languages}
        selectedValue={languageSelectedValues}
        isCreatable={false}
        menuPlacement={"auto"}
        onChange={(e) => { handleInputChange(e, "languages") }}
        isMultiValue={true}
        isRequired={true}
      />
       <ConstantScreen.TextArea
        labelTxt="Professional Summary"
        inputId="description"
        textareaClass="descriptionInput"
        txtAreaStyle={{ borderRadius: '5px' }}
        valueText={data?.summary}
        onChange={(e) => handleInputChange(e, 'summary')}
        txtAreaWrapperStyle="descriptionWrapper"
        errMsg={closingErrors?.summary}
        placeholderTxt="Enter Professional Summary"
        isRequired={true}
      />
      <ConstantScreen.TextArea
        labelTxt="Why Should You Hire Me?"
        inputId="description"
        textareaClass="descriptionInput"
        txtAreaStyle={{ borderRadius: '5px' }}
        valueText={data?.hireMeQuestion}
        onChange={(e) => handleInputChange(e, 'hireMeQuestion')}
        txtAreaWrapperStyle="descriptionWrapper"
        errMsg={closingErrors?.hireMeQuestion}
        placeholderTxt="Enter Description"
        isRequired={true}
      />
      <div className="formLabel">
        <span className="fieldLabel">Referral Details</span>
      </div>
      {(data.referrals)?.map((item, index) => {
        return (
          <>
            <div className='referralHeadingDetails'>
              <span className='referralCount'>Referral {index + 1}</span>
              <button type="button" style={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={() => { handleRemoveReferral(index) }} className="removeFieldButton"><img className='deleteField' style={{width:"0.8rem"}} src={deleteIcon} alt='something went wrong'/></button>
            </div>
            <div className='webRespFieldsWrapper'>
            <ConstantScreen.InputText
              labelTxt="Reference Name"
              inputType="text"
              inputId="referenceName"
              inputClass="referenceNameInput"
              inputStyle={{ borderRadius: '5px' }}
              inputValue={item?.referrerName}
              onChange={(e) => handleInputChange(e, 'referrerName', index)}
              inputWrapperStyle="referenceNameWrapper"
              errMsg={closingErrors?.referrals && closingErrors?.referrals[index]?.referrerName}
              placeholderTxt="Enter reference name"
              isRequired={false}
            />
              <ConstantScreen.MobileNumberField
                labelTxt="Mobile No"
                inputId="referenceMobNo"
                inputClass="mobileNoInputNew"
                inputStyle={{ borderRadius: '5px' }}
                inputValue={item?.mobileNumber}
                onChange={(e) => handleInputChange(e, 'mobileNumber', index)}
                inputWrapperStyle="mobileNoWrapper"
                errMsg={closingErrors?.referrals && closingErrors?.referrals[index]?.mobileNumber}
                placeholderTxt="Enter Mobile Number"
                isRequired={false}
                maxLength={10}
                cntryCodeOptions={cntryCodeOptions}
                setCntryCodeOptions={setCntryCodeOptions}
                selectedCntryCode={selectedCntryCodes[index]}
                setSelectedCntryCode={(value) => handleSelectCntryCode(index, value)}
                data={data}
                setData={setData}
                changeReferralCode={true}
              />
            <ConstantScreen.InputText
              labelTxt="Email Id"
              inputType="text"
              inputId="referenceEmailId"
              inputClass="referenceEmailIdInput"
              inputStyle={{ borderRadius: '5px' }}
              inputValue={item?.email}
              onChange={(e) => handleInputChange(e, 'email', index)}
              inputWrapperStyle="referenceEmailIdWrapper"
              errMsg={closingErrors?.referrals && closingErrors?.referrals[index]?.email}
              placeholderTxt="Enter Email Id"
              isRequired={false}
            />
        <Modal
        isOpen={errModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={()=>setErrModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className='modalCloseDiv'><span onClick={()=>setErrModal(false)}>X</span></div>
        <div className='modalLottieDiv'>
          <Lottie className='lottieFile' animationData={Error} loop={true}/>
        </div>
        <div className='modalMsg'>{modalMsg}</div>
        <button className='modalOkBtn' onClick={()=>setErrModal(false)}>OK</button>
      </Modal>

      <Modal
        isOpen={successModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={()=>setsuccessModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className='modalCloseDiv'><span onClick={()=>setsuccessModal(false)}>X</span></div>
        <div className='modalLottieDiv'>
          <Lottie className='lottieFile' animationData={Success} loop={true}/>
        </div>
        <div className='modalMsg'>{modalMsg}</div>
        <button className='modalOkBtn' onClick={()=>setsuccessModal(false)}>OK</button>
      </Modal>
            </div>
          </>
        )
      }) }
      <ConstantScreen.AddField
        addFieldWrapper='fieldButton'
        fieldTxt='Add Referral'
        onClick={handleAddReferalDetails}
      />
    <ToastContainer autoClose={4000} />
    </div>
  )
}

export default ClosingDetails