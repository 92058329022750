import React, { useEffect, useState } from 'react';
import './WorkExperience.css';
import { ConstantScreen } from '../../ConstantsScreen/ConstantScreen';
import { getAPICall } from '../../APIMethods/APIMethods';
import { Url } from '../../Constants/ApiUrlConstants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Lottie from 'lottie-react';
import Error from '../../Assets/Opps.json'
import Success from '../../Assets/Success.json'
import deleteIcon from '../../Assets/delete.png'
import '../../Navigation/Navigation.css'
import moment from 'moment';
const WorkExperience = ({ data, setData, designationSelected, workExpErrors, setWorkExpErrors,setSubmitResume }) => {
    const [designationOptions, setDesignationOptions] = useState([]);
    const [errModal, setErrModal] = useState(false)
    const [successModal, setsuccessModal] = useState(false)
    const [modalMsg, setModalMsg] = useState("")

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            minWidth: window.screen.width > 1023 ? "30%" : "70%",
            maxWidth: window.screen.width > 1023 ? "50%" : "80%",
            borderRadius: "10px",
            padding: "10px"
        },
    };

    const handleAddExperience = () => {
        if (data.experiences.length < 3) {
            setData({
                ...data,
                experiences: [
                    ...data.experiences,
                    { companyName: '', location: '', profileSummary: '', designation: '', startDate: '', endDate: '', isIntern: false }
                ]
            });
        } else {
            // toast.warning("You can add upto 3 experiences")  
            setModalMsg("You can add upto 3 experiences.")
            setErrModal(true)
        }
    };

    useEffect(() => {
        getAPICall(Url.getAllDesignation)
            .then((res) => {
                const allDesignationArray = (res.DATA).map((item) => ({
                    value: item?.designationId,
                    label: item?.designationName
                }))
                setDesignationOptions(allDesignationArray)
            })
    }, [])


    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };
    const handleChange = (index, field, value) => {
        setSubmitResume(false)
        const newExperiences = [...data.experiences];
        if (field === "designation") {
            const designationDetails = {
                designationId: typeof value.value === 'string' ? null : value.value,
                designationName: value.label
            };
            newExperiences[index][field] = designationDetails;
            setData({ ...data, experiences: newExperiences });
        } else if (field === 'startDate' || field === 'endDate') {
            if (field == "startDate") {
                newExperiences[index]['endDate'] = null
            }
            newExperiences[index][field] = moment(value).format('YYYY-MM-DD');
            setData({ ...data, experiences: newExperiences });
        } else if (field === 'currentlyWorking') {
            newExperiences[index][field] = value === "true"; // Set true if 'present' value is received
            newExperiences[index]['endDate'] = null; // Set true if 'present' value is received
            setData({ ...data, experiences: newExperiences });
        } else {
            newExperiences[index][field] = value;
            setData({ ...data, experiences: newExperiences });
        }

        const errorMsg = validateField(field, value);
        setWorkExpErrors((prevErrors) => {
            // Ensure prevErrors is an array
            const updatedErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
            if (!updatedErrors[index]) {
                updatedErrors[index] = {};
            }
            updatedErrors[index][field] = errorMsg;
            return updatedErrors;
        });
    };
    const validateField = (field, value) => {
        let errorMsg = '';

        switch (field) {
            case 'companyName':
                if (!value.trim()) {
                    errorMsg = 'Company Name cannot be empty.';
                }
                break;
            case 'startDate':
                if (value == "") {
                    errorMsg = 'Start Date cannot be empty.';
                }
                break;
            case 'endDate':
                if (!value) {
                    errorMsg = 'End Date cannot be empty.';
                }
                break;
            case 'profileSummary':
                if (!value.trim()) {
                    errorMsg = 'Responsibilities cannot be empty.';
                }
                break;
            case 'designation':
                if (value == null) {
                    errorMsg = 'Designation Name cannot be empty.';
                }
                break;
            default:
                break;
        }

        return errorMsg;
    };
    const handleRemoveExperience = (indexToRemove) => {
        if (data.experiences.length > 1) {
            if (indexToRemove >= 0 && indexToRemove < data.experiences.length) {
                const newExperiences = data.experiences.slice();
                newExperiences.splice(indexToRemove, 1);
                setData({ ...data, experiences: newExperiences });
            } else {
                console.error('Invalid index for removing experience:', indexToRemove);
            }
        } else {
            // toast.warning("Please add atleast one experience before removing.")
            setModalMsg("Please add atleast one experience before removing.")
            setErrModal(true)
        }
    };
    return (
        <div className="workExpform">
            {data?.experiences?.map((exp, index) => (
                <div className="workExpFormFields" key={index}>
                    <div className='experinceHeadingDetails'>
                        <span className='experienceCount'>Experience {index + 1}</span>
                        <button type="button" onClick={() => { handleRemoveExperience(index) }} className="removeFieldButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className='deleteField' style={{ width: "0.8rem" }} src={deleteIcon} alt='something went wrong' /></button>
                    </div>
                    <div className='webRespFieldsWrapper'>
                        <ConstantScreen.SelectField
                            labelTxt="Job Title"
                            inputId="designation"
                            isCreatable={true}
                            selectedValue={designationSelected[index]?.label ? designationSelected[index] : 'Select....'}
                            onChange={(e) => handleChange(index, 'designation', e)}
                            inputWrapperStyle="designationWrapper"
                            selectOptions={designationOptions}
                            placeholderTxt={"Select Designation"}
                            menuPlacement={"auto"}
                            errMsg={workExpErrors[index]?.designation}
                            isRequired={true}
                        />
                        <ConstantScreen.InputText
                            labelTxt='Company Name'
                            inputType='text'
                            inputId={`companyName-${index}`}
                            inputClass='companyNameInput'
                            inputStyle={{ borderRadius: '5px' }}
                            inputValue={exp.companyName}
                            onChange={(e) => handleChange(index, 'companyName', e.target.value)}
                            inputWrapperStyle='companyNameWrapper'
                            errMsg={workExpErrors[index]?.companyName}
                            placeholderTxt='Enter Company Name'
                            isRequired={true}
                        />
                        <ConstantScreen.DatePickerField
                            labelTxt='From'
                            inputType='date'
                            inputId={`startDate-${index}`}
                            inputClass='startDateInput'
                            inputStyle={{ borderRadius: '5px' }}
                            selectedValue={exp.startDate}
                            onChange={(e) => handleChange(index, 'startDate', e)}
                            inputWrapperStyle='startDateWrapper'
                            errMsg={workExpErrors[index]?.startDate}
                            showMonthYearPicker={true}
                            showYearPicker={false}
                            dateFormat={"MMM-yyyy"}
                            // maxDate={data.experiences[index - 1]?.startDate || exp.endDate || new Date()}
                            maxDate={new Date()}
                            placeholderTxt='Enter From Date'
                            isRequired={true}
                        />
                        <ConstantScreen.DatePickerField
                            disabledData={exp.currentlyWorking}
                            labelTxt='To'
                            inputType='date'
                            inputId={`endDate-${index}`}
                            inputClass='endDateInput'
                            inputStyle={{ borderRadius: '5px' }}
                            showMonthYearPicker={true}
                            showYearPicker={false}
                            dateFormat={"MMM-yyyy"}
                            maxDate={new Date()}
                            minDate={exp.startDate}
                            // maxDate={data.experiences[index - 1]?.startDate || new Date()}
                            // minDate={exp.startDate || ""}
                            selectedValue={exp.endDate}
                            onChange={(e) => handleChange(index, 'endDate', e)}
                            inputWrapperStyle='endDateWrapper'
                            placeholderTxt='Enter To Date'
                            isRequired={true}
                            errMsg={workExpErrors[index]?.endDate}
                        />
                        <div className='currentWorkDiv' style={{display : (data.experiences?.filter(v => v?.currentlyWorking).length > 0 && !exp?.currentlyWorking) ? "none" : ''}}>
                            <input type="checkbox" name="" id={`currentlyWorking${index}`}
                                checked={exp.currentlyWorking}
                                value={exp.currentlyWorking ? false : true}
                                onChange={(e) => {
                                    handleChange(index, 'currentlyWorking', e.target.value)
                                }}
                            />
                            <label
                                htmlFor={`currentlyWorking${index}`}>
                                Currently Working Here
                            </label>
                        </div>
                        <ConstantScreen.TextArea
                            labelTxt="Responsibilities"
                            inputId="description"
                            textareaClass="descriptionInput"
                            txtAreaStyle={{ borderRadius: '5px' }}
                            valueText={exp?.profileSummary}
                            onChange={(e) => handleChange(index, 'profileSummary', e.target.value)}
                            txtAreaWrapperStyle="descriptionWrapper"
                            errMsg={workExpErrors[index]?.profileSummary}
                            placeholderTxt="Enter Responsibilities"
                            isRequired={true}
                        />
                    </div>
                </div>
            ))}
            <ConstantScreen.AddField
                addFieldWrapper='fieldButton'
                fieldTxt='Add Experience'
                onClick={handleAddExperience}
            />
            <ToastContainer autoClose={6000} />
            <Modal
                isOpen={errModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setErrModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
            >
                <div className='modalCloseDiv'><span onClick={() => setErrModal(false)}>X</span></div>
                <div className='modalLottieDiv'>
                    <Lottie className='lottieFile' animationData={Error} loop={true} />
                </div>
                <div className='modalMsg'>{modalMsg}</div>
                <button className='modalOkBtn' onClick={() => setErrModal(false)}>OK</button>
            </Modal>

            <Modal
                isOpen={successModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setsuccessModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
            >
                <div className='modalCloseDiv'><span onClick={() => setsuccessModal(false)}>X</span></div>
                <div className='modalLottieDiv'>
                    <Lottie className='lottieFile' animationData={Success} loop={true} />
                </div>
                <div className='modalMsg'>{modalMsg}</div>
                <button className='modalOkBtn' onClick={() => setsuccessModal(false)}>OK</button>
            </Modal>
        </div>
    );
};

export default WorkExperience;
