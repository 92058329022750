import React,{useRef} from 'react'
import MobileResumePreview from '../MobileResumePreview/MobileResumePreview'
import './ShowGeneratedResume.css'
import DownloadIcon from '../../Assets/Download.svg'
import { ConstantScreen } from '../../ConstantsScreen/ConstantScreen'
import { PDFExport } from '@progress/kendo-react-pdf'
import { useNavigate } from 'react-router-dom'
import editIcon from '../../Assets/edit.svg'

const ShowGeneratedResume = ({allResumeDetails,audioFile,base64Img}) => {
 const navigate = useNavigate()
  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  const handleDownloadResume = () =>{
    if (pdfExportComponent?.current) {
      pdfExportComponent?.current.save();
    }
  }
  const handleLogout = () => {
    sessionStorage.clear()
    navigate('/login',{state:{login:true}})
  }
  return (
    <div className='showResumeDetails'>
        {/* <button type="button" className="editIconBtn" onClick={() => {handleLogout() }}> Login </button> */}
        <MobileResumePreview audioFile={audioFile}  base64Img={base64Img} allResumeDetails={allResumeDetails}/>
        {/* <button type="button" className="showResumeButton" onClick={() => { handleDownloadResume() }}>
                    <img src={DownloadIcon} alt='' /> Download Pdf
                  </button> */}
                  {/* {editResume && */}
                  {/* <button className='nextButton' name='submit' onClick={()=>{handleLogout()}}>Logout</button> */}
                {/* } */}
        <div ref={container} style={{ height: "0", overflow: 'hidden' }}>
        <PDFExport forcePageBreak=".page-break" keepTogether='.together' ref={pdfExportComponent}
          paperSize="A4" margin={{ top: 70, left: 20, right: 20, bottom: 0 }}
          author="KendoReact Team" fileName={`${allResumeDetails?.firstName}'s Resume`}>
          <ConstantScreen.MobileResumePreview allResumeDetails={allResumeDetails} />
        </PDFExport>
        <div className='webCopyrightDiv' style={{position : 'absolute', bottom : 0}}>ShareResume.co powered by&nbsp;<span className="hyperlinkQuikHire">QuikHire.ai</span></div>
      </div>
    </div>
  )
}

export default ShowGeneratedResume