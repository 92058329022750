import { Url } from "../Constants/ApiUrlConstants";

const CSRF_Token = 'YmUyYjAzODItOGIyZi00Njk1LThhOGMtYjhkYzVmZDY0YjI2';

export const getAPICall = (api) => {
  const Token = sessionStorage.getItem('token');
  if (Token != "" || Token != null) {
    return fetch(api, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': CSRF_Token,
        "Authorization": "Bearer " + Token,
      },
    })
      .then((respone) => respone.json())
  } else {
    return fetch(api, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': CSRF_Token,
        // "Authorization": "Bearer " +Token,
      },
    })
      .then((respone) => respone.json())
  }

}


export const copyFunction = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  if (navigator.userAgent.match(/ipad|iphone/i)) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
  } else {
      textArea.select();
  }
  document.execCommand('copy');
  document.body.removeChild(textArea);
}



//Method for With Json Converted body
export const postAPICall = (api, body) => {
  const Token = sessionStorage.getItem('token');
  if (Token != "" || Token != null) {
    return fetch(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": "Bearer " + Token,
        'X-CSRF-Token': CSRF_Token
      },
      body: JSON.stringify(body),
    })
      .then((respone) => respone.json())
  } else {
    return fetch(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': CSRF_Token
      },
      body: JSON.stringify(body),
    })
      .then((respone) => respone.json())
  }

}

//Method For Registration User Active Deactive
export const postStringAPICall = (api, body) => {
  const Token = sessionStorage.getItem('token');
  if (Token != "" || Token != null) {
    return fetch(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": "Bearer " + Token,
        'X-CSRF-Token': CSRF_Token
      },
      body: body,
    })
      .then((respone) => respone.json())
  } else {
    return fetch(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': CSRF_Token
      },
      body: body,
    })
      .then((respone) => respone.json())
  }

}
export const putAPICall = (api, body) => {
  const Token = sessionStorage.getItem('token');
  return fetch(api, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Authorization": "Bearer " + Token,
      'X-CSRF-Token': CSRF_Token
    },
    body: JSON.stringify(body),
  })
    .then((respone) => respone.json())
}

export const deleteAPICall = (api) => {
  const Token = sessionStorage.getItem('token');
  return fetch(api, {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Authorization": "Bearer " + Token,
      'X-CSRF-Token': CSRF_Token
    },
  })
    .then((respone) => respone.json())
}


const GetAuth = (bool) => {
  let token = sessionStorage.getItem("token");
  return bool ? {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer " + token,
    "X-CSRF-Token": CSRF_Token
  } : {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": CSRF_Token
  }
}

export const ApiCall = (
  APIUrl,
  Method,
  isAuthorizationRequired = false,
  ApiName = "",
  Body = null,
) => {

  let APIPromise1 = new Promise((resolve, reject) => {
    const URLOptions = Method?.toUpperCase() == "GET" ? {
      method: "GET",
      headers: GetAuth(isAuthorizationRequired),
      redirect: 'follow'
    } : {
      method: Method,
      headers: GetAuth(isAuthorizationRequired),
      redirect: 'follow',
      body: JSON.stringify(Body),
    }
    fetch(APIUrl, URLOptions)
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(`${Method} ${ApiName} error`, err);
        reject(err);
      });
  });
  return APIPromise1;
};

export const DocumentUploader =  (file, appendVariable, folderName) =>{
  console.log({file, appendVariable, folderName})
  let fileUrl = new Promise((resolve, reject)=>{
    let token = sessionStorage.getItem("token");
    if (file) {
      const formdata = new FormData();
      formdata.append(appendVariable, file);
      
      const requestOptions = {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "X-CSRF-Token": CSRF_Token
        },
        body: formdata,
      };
      
      fetch(Url.UploadDocumentToBucket.replace("{folderName}",folderName), requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("appendVariable",result)
          if(result?.SUCCESS){
          resolve(result?.DATA)
          }else{
            reject()
          }
        })
        .catch((error) => console.error(error));
    }
  })
  return fileUrl
 
}