import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CertificationDetails.css';
import { ConstantScreen } from '../../ConstantsScreen/ConstantScreen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Lottie from 'lottie-react';
import Error from '../../Assets/Opps.json'
import Success from '../../Assets/Success.json'
import deleteIcon from '../../Assets/delete.png'
import moment from 'moment';

const CertificationDetails = ({ data, setData, certificationErrors,setCertificationErrors,setSubmitResume }) => {
    const [errModal,setErrModal] = useState(false)
    const [successModal,setsuccessModal] = useState(false)
    const [modalMsg,setModalMsg] = useState("")

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          minWidth:window.screen.width>1023?"30%":"70%",
          maxWidth:window.screen.width>1023?"50%":"80%",
          borderRadius:"10px",
          padding:"10px"
        },
      };

    const handleAddCertificationDet = () => {
        if(data.certifications.length < 3){
        setData({
            ...data,
            certifications: [
                ...data.certifications,
                { certificateName: '', instituteName: '', startDate: '', endDate: '' }
            ]
        });
    }else{
        // toast.warning("You can add upto 3 Certifications");
        setModalMsg("You can add upto 3 Certifications.")
        setErrModal(true)
    }
    };
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };
    const handleChange = (index, field, value) => {
        setSubmitResume(false)
        const newCertificationDets = [...data.certifications];
        if (field == 'startDate' || field == 'endDate') {
            newCertificationDets[index][field] = moment(value).format('YYYY-MM-DD');
            setData({ ...data, certifications: newCertificationDets });
        } else {
            newCertificationDets[index][field] = value;
            setData({ ...data, certifications: newCertificationDets });
        }
    };
    const handleRemoveCertification = (indexToRemove) => {
        // if (data.certifications.length > 1) {
            if (indexToRemove >= 0 && indexToRemove < data.certifications.length) {
                const newCertifications = data.certifications.slice();
                newCertifications.splice(indexToRemove, 1);
                setData({ ...data, certifications: newCertifications });
            } else {
                console.error('Invalid index for removing Certification:', indexToRemove);
            }
        // } 
        // else {
        //     // toast.warning("Please add atleast one Certification before removing.");
        //     setModalMsg("Please add atleast one Certification before removing.")
        //     setErrModal(true)
        // }
    };
    return (
        <div className="CertificationDetform">
            {(data?.certifications)?.map((certificateItem, index) => (
                <div className="CertificationDetFormFields" key={index}>
                    <div className='experinceHeadingDetails'>
                        <span className='experienceCount'>Certification {index + 1}</span>
                        <button type="button" style={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={() => { handleRemoveCertification(index) }}className="removeFieldButton"><img className='deleteField' style={{width:"0.8rem"}} src={deleteIcon} alt='something went wrong'/></button>
                    </div>
                    <div className='webRespFieldsWrapper'>
                    <ConstantScreen.InputText
                        labelTxt='Course Name'
                        inputType='text'
                        inputId={`courseName-${index}`}
                        inputClass='courseNameInput'
                        inputStyle={{ borderRadius: '5px' }}
                        inputValue={certificateItem.certificateName}
                        onChange={(e) => handleChange(index, 'certificateName', e.target.value)}
                        inputWrapperStyle='courseNameWrapper'
                        errMsg={certificationErrors?.certifications ? certificationErrors?.certifications[index]?.certificateName : ""}
                        placeholderTxt='Enter Course Name'
                        isRequired={false}
                    />
                    <ConstantScreen.InputText
                        labelTxt='Institute Name'
                        inputType='text'
                        inputId={`instituteName-${index}`}
                        inputClass='instituteNameInput'
                        inputStyle={{ borderRadius: '5px' }}
                        inputValue={certificateItem.instituteName}
                        onChange={(e) => handleChange(index, 'instituteName', e.target.value)}
                        inputWrapperStyle='instituteNameWrapper'
                        errMsg={certificationErrors?.certifications ? certificationErrors?.certifications[index]?.instituteName : ""}
                        placeholderTxt='Enter Institute Name'
                        isRequired={false}
                    /> 
                    <ConstantScreen.DatePickerField
                        labelTxt='Certification Issued Date'
                        inputId={`endDate-${index}`}
                        inputClass='endDateInput'
                        inputStyle={{ borderRadius: '5px' }}
                        selectedValue={certificateItem.endDate}
                        onChange={(date) => handleChange(index, 'endDate', date)}
                        inputWrapperStyle='endDateWrapper'
                        showMonthYearPicker={true}
                        showYearPicker={false}
                        dateFormat={"MMM-yyyy"}
                        errMsg={certificationErrors?.certifications ? certificationErrors?.certifications[index]?.endDate : ""}
                        maxDate={new Date()}
                        minDate={certificateItem.startDate}
                        placeholderTxt='Enter To Date'
                        isRequired={false}
                    />
                    </div>
                </div>
            ))}
            <ConstantScreen.AddField
                addFieldWrapper='fieldButton'
                fieldTxt='Add Course'
                onClick={handleAddCertificationDet}
            />

<Modal
        isOpen={errModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={()=>setErrModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className='modalCloseDiv'><span onClick={()=>setErrModal(false)}>X</span></div>
        <div className='modalLottieDiv'>
          <Lottie className='lottieFile' animationData={Error} loop={true}/>
        </div>
        <div className='modalMsg'>{modalMsg}</div>
        <button className='modalOkBtn' onClick={()=>setErrModal(false)}>OK</button>
      </Modal>

      <Modal
        isOpen={successModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={()=>setsuccessModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className='modalCloseDiv'><span onClick={()=>setsuccessModal(false)}>X</span></div>
        <div className='modalLottieDiv'>
          <Lottie className='lottieFile' animationData={Success} loop={true}/>
        </div>
        <div className='modalMsg'>{modalMsg}</div>
        <button className='modalOkBtn' onClick={()=>setsuccessModal(false)}>OK</button>
      </Modal>
        </div>
    );
};
<ToastContainer autoClose={4000} />

CertificationDetails.propTypes = {
    data: PropTypes.shape({
        certifications: PropTypes.arrayOf(
            PropTypes.shape({
                courseName: PropTypes.string,
                instituteName: PropTypes.string,
                fromDate: PropTypes.string,
                toDate: PropTypes.string
            })
        )
    }).isRequired,
    setData: PropTypes.func.isRequired
};

export default CertificationDetails;
