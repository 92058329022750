export const field = {
    fieldValue: '',
    isValidField: true,
    errorField: ""

};
export const companyValidation = (name,val) => {
    let NameRegex = /^(?=.*[a-zA-Z])([a-zA-Z0-9&.\s]+)$/
    if(name == "Company Description"){
        NameRegex = /^(?!^[0-9]*$)(?!^[^\w\s]*$)(?!^\.$)(?=.*[A-Za-z])[\w\W]*$/
    }
    else if(name == "Company Website Link"){
        // NameRegex = /^(https:\/\/)?www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
        NameRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\/?$/
    }
    else if(name == "Corporate Address"){
        NameRegex = /^(?!^[0-9]*$)(?!^[^\w\s]*$)(?!^\.$)(?=.*[A-Za-z])[\w\W]*$/
    }

    if (val?.trim().length !== 0) {
        if(NameRegex?.test(val?.trim())){
            if(name == "Company Website Link"){
                if(val.includes("www.")){
                    let cutout = val.substring(val.indexOf("www.") + 4)
                    if( NameRegex.test(cutout)){
                        return {
                            fieldValue: val,
                            isValidField: true,
                            errorField: ''
                        };
                } else {
                    return {
                        fieldValue: val,
                        isValidField: false,
                        errorField: `Invalid input`
                    };
                }

            } else {
                if(NameRegex?.test(val?.trim())){
                    return {
                        fieldValue: val,
                        isValidField: true,
                        errorField: ''
                    };
            } else {
                return {
                    fieldValue: val,
                    isValidField: false,
                    errorField: `Invalid input`
                };
            }
            } 
        } else {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ''
            };
        }   
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }   
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: `${name} is required`
        };
    }
}

//validation only allow alphabates, required field and apply regex according to field name
export const onlyAlphabets = (name, val) => {
    let regexval = /^[a-zA-Z ]+$/;
    //let  regexval = /^(?!\d+$)(?:[a-zA-Z][a-zA-Z @&$]*)?$/
    if (String(name).toLowerCase() === "Full Name".toLowerCase()) {
        regexval = /^[a-zA-Z ]+$/;
    }
    else if (String(name).toLowerCase() == "Email Address".toLowerCase()) {
        regexval = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/
    }
    else if (String(name).toLowerCase() === "dob") {
        //regexval = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
        regexval = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/
    }
    else if ( String(name).toLowerCase() === "Company Name".toLowerCase()) {
        regexval = /^(?!\d+$)(?:[a-zA-Z0-9.][a-zA-Z0-9. @&$]*)?$/
    }
    else if (String(name).toLowerCase() === "University For Graduation".toLowerCase() || String(name).toLowerCase() === "University Of Certification".toLowerCase() || String(name).toLowerCase() === "Postgraduation University".toLowerCase()) {
        regexval = /[a-zA-Z]/
    } else if (String(name).toLowerCase() === "Beneficiary Name".toLowerCase()) {
        regexval = /^[a-zA-Z ]+$/;
    } else if (String(name).toLowerCase() === "IFSC Code".toLowerCase()) {
        regexval = /^(?!\d+$)(?:[A-Z0-9]*)?$/
    } else if (String(name).toLowerCase() === "Clinic/Business GSTIN".toLowerCase()) {
        regexval = /^(?!\d+$)(?:[A-Z0-9]*)?$/
    } else if (String(name).toLowerCase() === "PAN Number".toLowerCase()) {
        regexval = /^(?!\d+$)(?:[A-Z0-9]*)?$/
    } else if (String(name).toLowerCase() === "PAN Name".toLowerCase()) {
        regexval = /^[a-zA-Z ]+$/
    } else if (String(name).toLowerCase() === "Business Entity Information".toLowerCase()) {
        regexval = /^(?!\d+$)(?:[a-zA-Z0-9]*)?$/
    }

    if (val?.trim().length !== 0) {
        if (regexval.test(val?.trim())) {
            if (String(name).toLowerCase() === "PAN Name".toLowerCase()) {
                regexval = /^[A-Z ]+$/
                if (regexval.test(val?.trim())) {
                    return {
                        fieldValue: val,
                        isValidField: true,
                        errorField: ''
                    };
                } else {
                    return {
                        fieldValue: val,
                        isValidField: false,
                        errorField: `${name} should be in capital letters only`
                    };
                }
            } else {
                return {
                    fieldValue: val,
                    isValidField: true,
                    errorField: ''
                };
            }

        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: `${name} is required`
        };
    }
}

//validation only allow number, required field and length of the number
export const onlyNumber = (val,  name = null, len) => {
    if (val?.trim()?.length !== 0) {
        if(name=="Mobile Number"){
            if (/^\d{10}$/.test(val?.trim()) ) {
                return {
                    fieldValue: val,
                    isValidField: true,
                    errorField: ""
                };
            } else {
                return {
                    fieldValue: val,
                    isValidField: false,
                    errorField: `Invalid input`
                };
            }
        }else{
            if (/^[0-9]\d*$/.test(val?.trim()) && val?.trim().length <= len) {
                return {
                    fieldValue: val,
                    isValidField: true,
                    errorField: ""
                };
            } else {
                return {
                    fieldValue: val,
                    isValidField: false,
                    errorField: `Invalid input`
                };
            }
        }
       
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: name !== null ? `${name} is required` : `This field is required`
        };
    }
}
export const onlyNumberAndNonMandatory = (val,  name = null, len) => {
    if (val?.trim()?.length !== 0) {
        if(name=="Mobile Number"){
            if (/^[0-9]\d*$/.test(val?.trim()) ) {
                return {
                    fieldValue: val,
                    isValidField: true,
                    errorField: ""
                };
            } else {
                return {
                    fieldValue: val,
                    isValidField: false,
                    errorField: `Invalid input`
                };
            }
        }else{
            if (/^[0-9]\d*$/.test(val?.trim()) && val?.trim().length <= len) {
                return {
                    fieldValue: val,
                    isValidField: true,
                    errorField: ""
                };
            } else {
                return {
                    fieldValue: val,
                    isValidField: false,
                    errorField: `Invalid input`
                };
            }
        }
       
    }
}
export const onlyBankAccountnumber = (val, len, name = null) => {
    if (val?.trim().length !== 0) {
        if (/^[0-9]\d*$/.test(val?.trim()) && val?.trim().length < 18 && /^[0-9]\d*$/.test(val?.trim()) && val?.trim().length > 8) {


            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: name !== null ? `${name} is required` : `This field is required`
        };
    }
}

export const onlyPincode = (val, len, name = null) => {
    if (val?.trim().length !== 0) {
        if (!/^[0]*$/.test(val?.trim()) && /^[0-9]\d*$/.test(val?.trim()) && val?.trim().length <= len) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: name !== null ? `${name} is required` : `This field is required`
        };
    }
}


export const onlyLinkedInProfile = (name, val ) => {
    const linkedinRegex = /^(https?:\/\/)?(www\.|in\.)?linkedin\.com\/(?:[a-zA-Z]{2,}\/)?(in|pub|company|groups)\/[a-zA-Z0-9_-]+\/?$/;


    if (val?.trim().length !== 0) {
        if (linkedinRegex.test(val?.trim()) ) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {

            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {

        return {
            fieldValue: val,
            isValidField: false,
            errorField: name !== null ? `${name} is required` : `This field is required`
        };
    }
}

export const onlyLinkedInProfileNonMandatory = (name, val ) => {
    const linkedinRegex = /^(https?:\/\/)?(www\.|in\.)?linkedin\.com\/(?:[a-zA-Z]{2,}\/)?(in|pub|company|groups)\/[a-zA-Z0-9_-]+\/?$/;


    if (val && val?.trim().length !== 0) {
        if (linkedinRegex.test(val?.trim()) ) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {

            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid LinkedIn input`
            };
        }
    }
}

export const onlyValidYearReq = (val, name = null) => {
    let year = new Date();
    if (val?.trim().length !== 0) {
        if (/^[1-9]\d*$/.test(val?.trim()) && val?.trim().length === 4 && val?.trim() <= year.getFullYear()) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: name !== null ? `${name} is required` : `This field is required`
        };
    }
}
export const onlyValidYear = (val) => {
    let year = new Date();
    if (val?.length !== 0) {
        if (/^[1-9]\d*$/.test(val?.trim()) && val?.trim().length === 4 && val?.trim() <= year.getFullYear()) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: true,
            errorField: ``
        };
    }
}


export const onPassword = (val) => {
    if (val?.trim().length !== 0) {
         if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,16}$/.test(val)) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: 'Password must contain at least 1 lower case, 1 upper case, 1 numeric, special character, length maximum of 16 and minimum of 8'
                // errorField: 'Password must contain letters and numbers and length  minimum of 8'
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: 'This Field is required'
        };
    }
}

export const onlySalaryEntry = (field, val) => {
    if(val?.length==0){
       return{
        fieldValue: val,
        isValidField: true,
        errorField: ""
       }
    }
    else if (/^(?!-)(?!.*[-.]{2})[0-9]+(?:\.[0-9])?(?:-[0-9]+(?:\.[0-9])?)?$/.test(val)) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
                // errorField: 'Password must contain letters and numbers and length  minimum of 8'
            };
        }
}

export const onAllowAllCharacters = (name, val) => {
    if(name == "Salary"){
        if (val?.trim().length !== 0) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ''
            };
        }
    }else{
        if (val?.trim().length !== 0) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ''
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `${name} is required`
            };
        }
    }
}

export const matchPassword = (val1, val2) => {
    if (val1 === val2) {
        return {
            fieldValue: val1,
            isValidField: true,
            errorField: ''
        };
    } else {
        return {
            fieldValue: val1,
            isValidField: false,
            errorField: 'Passwords did not match'
        };
    }
}

export const nonRequired = (val) => {
    let regexval = /^[a-zA-Z ]+$/;
    if (val !== '' && val?.trim().length !== 0) {
        if (regexval.test(val?.trim())) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ''
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: true,
            errorField: ''
        };
    }
}

export const nonRequiredNum = (val, len) => {
    if (val !== '' || val?.trim().length !== 0) {
        if (/^[1-9]\d*$/.test(val?.trim()) && val?.trim().length === len) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: true,
            errorField: ''
        };
    }
}

export const AddressValidation = (val) => {
    if (val?.trim().length !== 0) {
        return {
            fieldValue: val,
            isValidField: true,
            errorField: ''
        };
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: 'This Field is required'
        };
    }
}

export const onHeightChange = (val, name = null) => {
    let reg = /^[1-9]\d*$/;
    if (val?.trim().length >= 2 && reg.test(val?.trim())) {
        return {
            fieldValue: val,
            isValidField: true,
            errorField: ''
        };
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: name !== null ? `${name} is required` : `This field is required`
        };
    }
}

export const fewNumber = (val, len, name = null) => {
    if (val?.trim().length !== 0) {
        if (/^[1-9]\d*$/.test(val?.trim()) && val?.trim().length < 16 && /^[1-9]\d*$/.test(val?.trim())) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {
            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {
        return {
            fieldValue: val,
            isValidField: false,
            errorField: name !== null ? `${name} is required` : `This field is required`
        };
    }
}

//use below code only When Validations are previously applied and if we can't remove the code used for validation

export const noValidationRequired = (name, val) => {
    // if (val?.trim().length !== 0) {
    return {
        fieldValue: val,
        isValidField: true,
        errorField: ''
    };
    // } else {
    //     return {
    //         fieldValue: val,
    //         isValidField: false,
    //         errorField: `${name} is required`
    //     };
    // }
}


export const emailValidationForJobPoster = (name, val ) => {

    const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|gmail\.|hotmail\.)(?!yahoo|gmail|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|hotmail\.)(?!yahoo|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    
    if (val?.trim().length !== 0) {
        if (onlyCorporateMails.test(val?.trim())) {
            return {
                fieldValue: val,
                isValidField: true,
                errorField: ""
            };
        } else {

            return {
                fieldValue: val,
                isValidField: false,
                errorField: `Invalid input`
            };
        }
    } else {

        return {
            fieldValue: val,
            isValidField: false,
            errorField: name !== null ? `${name} is required` : `This field is required`
        };
    }
}
