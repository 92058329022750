import { getActivProfile, webUrlLink } from "../Profiles/Profiles";
import { ProfileConstant } from "./ProfileConstants";

const baseURL = getActivProfile(ProfileConstant.Prod);

export const ResumeURL = webUrlLink.Prod

export const Url = {
  getAllSkills: baseURL + "skill/",
  getAllLanguages: baseURL + "language/",
  getAllDesignation: baseURL + "designation/",
  getAllCities: baseURL + "city/country/{countryCodeId}/",
  // submitResumeDetails: baseURL + "resume", //prod 
  submitResumeDetails: baseURL + "resume/create", //int and uat
  editResumeDetails: baseURL + "resume/edit", //int and uat
  // getResumeByUserID: baseURL + "resume/user/{userUid}", //prod
  getResumeByUserID: baseURL + "user/resume/{userUid}", //int and uat
  getCountryCode: baseURL + 'country',
  mobileNoVerification: baseURL + 'public/user/countrycode/{countrycode}/username/{mobileNumber}',
  emailVerification: baseURL + 'public/user/{email}',
  sendOTP: baseURL + 'sms/otp',
  forgotPwd: baseURL + 'user/forgot-password',
  UserLogin: baseURL + 'login',
  getuser: baseURL + "user/dashboard",
  UploadDocumentToBucket : baseURL + 's3/public/{folderName}/upload',
  checkUserUid : baseURL + "public/user/uid/{uid}",
  privateCheckUserUid : baseURL + "user/resume/uid/{uid}"
}