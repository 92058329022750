import React, { useEffect, useState } from "react";
import "./Login.css";
import logoImageUrl from "../Assets/QuickHireLogo.svg";
import Modal from "react-modal";
import Lottie from "lottie-react";
import Oops from "../Assets/Opps.json";
import completed from "../Assets/Success.json";
import { useLocation, useNavigate } from "react-router-dom";
import mobileImg from "../Assets/mobileImg.svg";
import OtpInput from "react-otp-input";
import eyeIcon from "../Assets/eyeIcon.svg";
import hiddenEyeIcon from "../Assets/hiddenEyeIcon.svg";
import { SHA256 } from "crypto-js";
import { ResumeURL, Url } from "../Constants/ApiUrlConstants";
import { field } from "../Validation/Validation";
import '../Register/Register.css'
import { ApiCall, getAPICall, postAPICall } from "../APIMethods/APIMethods";

const Login = () => {
  const navigate = useNavigate();
  const urlRegex = /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]*[-a-zA-Z0-9@:%_\+\]~#?&\/=])*/i

  const [otp, setOtp] = useState("");
  const [OTPAuditID, setOTPAuditID] = useState("");
  const [mobileNumber, setmobileNumber] = useState(field);
  const [resetOtp, setresetOtp] = useState(false);
  const [reason, setreason] = useState(field);
  const [isotpSent, setisotpSent] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [successPopup, setSuccessPopup] = useState(false);
  const [showBtns, setShowBtns] = useState(true)
  const [hidePwd, setHidePwd] = useState({
    pwd: false,
    npwd: false,
    cnpwd: false,
  });
  const [forgotPassFlow, setForgotPassFlow] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState({
    countryCodeId: 1,
    countryName: "India",
    countryCode: "91",
    currency: "₹",
    currencyPosition: true,
    countryFlag: "IN",
  });
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [popupMsg, setPopupMsg] = useState("");
  const [forgotPwd, setForgotPwd] = useState(false);
  const [modal, setModal] = useState(false);
  var mnoRegex = /^\d+$/;
  var bothUpperLowerCase = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
  var lowerCase = /.*[a-z].*/;
  var upperCase = /.*[A-Z].*/;
  var oneDigitRegex = /.*[0-9].*/;
  var oneSpclChar = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~`]).+$/;
  const [lgnDetails, setLgnDetails] = useState({
    countryCodeId: null,
    countryCode: "",
    mno: "",
    pwd: "",
    npwd: "",
    cnpwd: "",
  });
  const [screen, setScreen] = useState(1);
  const [errMsg, setErrMsg] = useState({
    cntryCode: "",
    mno: "",
    pwd: "",
    npwd: "",
    cnpwd: "",
  });
  const [otp2, setOtp2] = useState("");
  const [msgAuditId, setMsgAuditId] = useState();
  const propData = useLocation()
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };

  useEffect(() => {
    try {
      getAPICall(Url.getCountryCode)
        .then((res) => {
          setCountryCodes(res?.DATA); 
          setLgnDetails({
            ...lgnDetails,
            ['countryCodeId'] : res?.DATA[0]?.countryCodeId,
            ["countryCode"] : res?.DATA[0]?.countryCode
          })
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleLgnInputChange = (e, field) => {
    switch (field) {
      case "cntryCode":
        const value = JSON.parse(e.target.value);
        setLgnDetails({ ...lgnDetails, countryCode: value?.countryCode, countryCodeId: value?.countryCodeId });
        break;
      case "mno":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, mno: "Mobile number is required." });
        } else if (!mnoRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, mno: "Should contain only numbers" });
        } else if (e?.target?.value.length < 4) {
          setErrMsg({ ...errMsg, mno: "Minimum length should be 4" });
        }
        // else if (e?.target?.value.length > 10) {
        //   setErrMsg({ ...errMsg, mno: "Maximum length should be 10" })
        // }
        else if (!mnoRegex.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            mno: "Special characters and alphabets are not allowed.",
          });
        } else {
          setErrMsg({ ...errMsg, mno: "" });
        }
        if (e?.target?.value.length <= 10) {
          setLgnDetails({ ...lgnDetails, mno: e?.target?.value });
        }
        break;
      case "pwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, pwd: "Password is required." });
        } else if (!upperCase.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            pwd: "Should accept atleast one upper case letter.",
          });
        } else if (!lowerCase.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            pwd: "Should accept atleast one lower case letter.",
          });
        } else if (!oneDigitRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, pwd: "Should accept atleast one digit." });
        } else if (!oneSpclChar.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            pwd: "Should accept atleast one special character.",
          });
        } else if (e?.target?.value.length < 8) {
          setErrMsg({ ...errMsg, pwd: "Minimum length should be 8." });
        } else if (e?.target?.value.length > 16) {
          setErrMsg({ ...errMsg, pwd: "Maximum length should be 16." });
        } else {
          setErrMsg({ ...errMsg, pwd: "" });
        }
        setLgnDetails({ ...lgnDetails, pwd: e?.target?.value });
        break;
      case "npwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, npwd: "Password is required." });
        } else if (!upperCase.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            npwd: "Should accept atleast one upper case letter.",
          });
        } else if (!lowerCase.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            npwd: "Should accept atleast one lower case letter.",
          });
        } else if (!oneDigitRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, npwd: "Should accept atleast one digit." });
        } else if (!oneSpclChar.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            npwd: "Should accept atleast one special character.",
          });
        } else if (e?.target?.value.length < 8) {
          setErrMsg({ ...errMsg, npwd: "Minimum length should be 8." });
        } else if (e?.target?.value.length > 16) {
          setErrMsg({ ...errMsg, npwd: "Maximum length should be 16." });
        } else {
          setErrMsg({ ...errMsg, npwd: "" });
        }
        setLgnDetails({ ...lgnDetails, npwd: e?.target?.value });
        break;
      case "cnpwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, cnpwd: "Confirm Password is required." });
        } else if (lgnDetails?.npwd != e?.target?.value) {
          setErrMsg({ ...errMsg, cnpwd: "Password doesn't match." });
        } else {
          setErrMsg({ ...errMsg, cnpwd: "" });
        }
        setLgnDetails({ ...lgnDetails, cnpwd: e?.target?.value });
        break;
      default:
        setErrMsg({ cntryCode: "", mno: "", pwd: "", npwd: "", cnpwd: "" });
        break;
    }
  };
  const handleNavigateDashoard = () => {
    navigate("/Dashboard")
  }
  const handleMobileNoVerification = () => {
    if (lgnDetails?.mno == "") {
      setPopupMsg("Mobile No. is required.");
      setModal(true);
    } else if (lgnDetails?.mno.length < 4) {
      setPopupMsg("Minimum length of mobile number should be 4.");
      setModal(true);
    } else if (lgnDetails?.mno.length > 10) {
      setPopupMsg("Maximum length of mobile number should be 10.");
      setModal(true);
    } else if (!mnoRegex.test(lgnDetails?.mno)) {
      setErrMsg({
        ...errMsg,
        mno: "Special characters and alphabets are not allowed.",
      });
    } else {
      try {
        getAPICall(Url.mobileNoVerification.replace("{countrycode}", lgnDetails?.countryCodeId).replace("{mobileNumber}", lgnDetails?.mno))
          .then((res) => {
            if (res?.SUCCESS == true && res?.DATA == true) {
              setScreen(2);
            } else if (res?.SUCCESS == true && res?.DATA == false) {
              setScreen(3);
              setForgotPassFlow(false)
            } else if (res?.SUCCESS == false && res?.DATA == "ROLE_APPLICANT") {
              setPopupMsg("Only recruiters can access this web application. Kindly log in through the mobile application.")
              setModal(true)
            } else {
              setPopupMsg(`This number is not registered with ${ResumeURL} Please create a resume for registration. `);
              setModal(true);
            }
          })
          .catch((e) => {
            console.log(e);
            setPopupMsg("Something went wrong.");
            setModal(true);
          });
      } catch (e) {
        console.log(e);
        setPopupMsg("Something went wrong.");
        setModal(true);
      }
    }
  };

  const handlePosterLogin = () => {
    if (lgnDetails?.mno == "") {
      setPopupMsg("Mobile No. is required.");
      setModal(true);
    } else if (lgnDetails?.mno.length < 4) {
      setPopupMsg("Minimum length should be 4.");
      setModal(true);
    } else if (lgnDetails?.mno.length > 10) {
      setPopupMsg("Maximum length should be 10.");
      setModal(true);
    } else if (lgnDetails?.pwd == "") {
      setPopupMsg("Password is required.");
      setModal(true);
    } else if (!upperCase.test(lgnDetails?.pwd)) {
      setPopupMsg("Password should contain atleast one upper case letter.");
      setModal(true);
    } else if (!lowerCase.test(lgnDetails?.pwd)) {
      setPopupMsg("Password should contain atleast one lower case letter.");
      setModal(true);
    } else if (!oneDigitRegex.test(lgnDetails?.pwd)) {
      setPopupMsg("Password should contain atleast one digit.");
      setModal(true);
    } else if (!oneSpclChar.test(lgnDetails?.pwd)) {
      setPopupMsg("Password should contain atleast one special character.");
      setModal(true);
    } else if (lgnDetails?.pwd.length < 8) {
      setPopupMsg("Minimum length of Password should be 8.");
      setModal(true);
    } else if (lgnDetails?.pwd.length > 16) {
      setPopupMsg("Maximum length of Password should be 16.");
      setModal(true);
    } else {
      let obj = {
        countryCodeId: lgnDetails?.countryCodeId,
        username: lgnDetails?.mno,
        password: SHA256(lgnDetails?.pwd).toString(),
      };
      try {
        postAPICall(Url?.UserLogin, obj)
          .then((ress) => {
            if (ress?.SUCCESS == true) {
              setLgnDetails({
                countryCodeId: null,
                mno: "",
                pwd: "",
                npwd: "",
                cnpwd: "",
              });
              let token = ress?.token;
              sessionStorage.setItem("token", token);
              getUserDataname();
            } else {
              setPopupMsg(ress?.message);
              setModal(true);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleOtpSend = () => {
    if (lgnDetails?.mno == "") {
      setPopupMsg("Mobile No. is required.");
      setModal(true);
    } else if (lgnDetails?.mno.length < 4) {
      setPopupMsg("Minimum length of mobile number should be 4.");
      setModal(true);
    } else if (lgnDetails?.mno.length > 10) {
      setPopupMsg("Maximum length of mobile number should be 10.");
      setModal(true);
    } else if (lgnDetails?.npwd == "") {
      setPopupMsg("New Password is required.");
      setModal(true);
    } else if (!upperCase.test(lgnDetails?.npwd)) {
      setPopupMsg("Password should contain atleast one upper case letter.");
      setModal(true);
    } else if (!lowerCase.test(lgnDetails?.npwd)) {
      setPopupMsg("Password should contain atleast one lower case letter.");
      setModal(true);
    } else if (!oneDigitRegex.test(lgnDetails?.npwd)) {
      setPopupMsg("Password should contain atleast one digit.");
      setModal(true);
    } else if (!oneSpclChar.test(lgnDetails?.npwd)) {
      setPopupMsg("Password should contain atleast one special character.");
      setModal(true);
    } else if (lgnDetails?.npwd.length < 8) {
      setPopupMsg("Minimum length of Password should be 8.");
      setModal(true);
    } else if (lgnDetails?.npwd.length > 16) {
      setPopupMsg("Maximum length of Password should be 16.");
      setModal(true);
    } else if (lgnDetails?.cnpwd == "") {
      setPopupMsg("Confirm password is required!");
      setModal(true);
    } else if (lgnDetails?.cnpwd != lgnDetails?.npwd) {
      setPopupMsg("Password doesn't match.");
      setModal(true);
    } else {
      let obj = {
        countryCodeId: lgnDetails?.countryCodeId,
        toNumber: lgnDetails?.mno,
        messageType: "OTP_SMS",
      };
      try {
        postAPICall(Url.sendOTP, obj)
          .then((res) => {
            setForgotPwd(forgotPassFlow)
            if (res?.SUCCESS == true) {
              setScreen(4);
              setMsgAuditId(res?.DATA);
            } else {
              setPopupMsg(res?.message);
              setModal(true);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleResend = () => {
    let obj = {
      web: true,
      countryCodeId: lgnDetails?.countryCodeId,
      toNumber: lgnDetails?.mno,
      messageType: "OTP_SMS",
    };
    try {
      postAPICall(Url.sendOTP, obj)
        .then((res) => {
          setForgotPwd(false);
          if (res?.SUCCESS == true) {
            setMsgAuditId(res?.DATA);
            setsuccessMsg("OTP sent successfully.");
            setSuccessModalIsOpen(true);
            setOtp2('')
          } else {
            setPopupMsg(res?.message);
            setModal(true);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleSetPwd = () => {
    if (otp2 == "") {
      setPopupMsg("Please enter OTP.");
      setModal(true);
    } else if (otp2?.length < 4) {
      setPopupMsg("Invalid OTP.");
      setModal(true);
    } else {
      let obj = {
        messageAuditId: msgAuditId,
        messageOTP: otp2,
        countryCode: {
          countryCodeId: lgnDetails?.countryCodeId,
        },
        mobileNumber: lgnDetails?.mno,
        password: SHA256(lgnDetails?.npwd).toString(),
      };
      try {
        ApiCall(Url.forgotPwd, 'PATCH', false, 'forgot pwd', obj)
          .then((res) => {
            if (res?.SUCCESS == true) {
              setPopupMsg("Password Set Successfully.");
              setSuccessPopup(true);
              // setLgnDetails({ cntryCode: "1", mno: "", pwd: "", npwd: "", cnpwd: "" })
            } else {
              setPopupMsg(res?.message);
              setModal(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleAutoLogin = () => {
    setSuccessPopup(false);
    let obj = {
      countryCodeId: lgnDetails?.countryCodeId,
      username: lgnDetails?.mno,
      password: SHA256(lgnDetails?.npwd).toString(),
      role: "APPLICANT",
    };
    try {
      postAPICall(Url?.UserLogin, obj)
        .then((ress) => {
          setLgnDetails({
            countryCodeId: null,
            mno: "",
            pwd: "",
            npwd: "",
            cnpwd: "",
          });
          if (ress?.SUCCESS == true) {
            let token = ress?.token;
            sessionStorage.setItem("token", token);
            getUserDataname();
          } else {
            setPopupMsg(ress?.message);
            setModal(true);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserDataname = () => {
    try {
      getAPICall(Url?.getuser)
        .then((res) => {
          const encryptedStringNavigation = `resumeEdit/${res?.DATA?.userUid}`;
          navigate("/Main/" + encryptedStringNavigation, { state: { editResume: true } });

          getAPICall(Url.getResumeByUserID.replace('{userUid}', res?.DATA?.userUid))
            .then((resDash) => {
              sessionStorage.setItem("user", JSON.stringify(resDash?.DATA));
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleForgotPwd = () => {
    setForgotPwd(true);
    setScreen(3);
    setForgotPassFlow(true)
  };
  const handlePwdShow = () => {
    setHidePwd({ ...hidePwd, pwd: !hidePwd?.pwd });
  };
  const handleNpwdShow = () => {
    setHidePwd({ ...hidePwd, npwd: !hidePwd.npwd });
  };
  const handleCnpwdShow = () => {
    setHidePwd({ ...hidePwd, cnpwd: !hidePwd.cnpwd });
  };
  const handleKeyPressed = (e, field) => {
    if (field == "login") {
      if (e?.key.toLowerCase() == "enter") {
        handlePosterLogin();
      }
    } else if (field == "mobileNoVerification" && screen == 1) {
      if (e?.key?.toLowerCase() == "enter") {
        handleMobileNoVerification();
      }
    }
  };

  useEffect(() => {
    if (otp2.length == 4) {
      handleSetPwd()
    }
  }, [otp2])

  return (
    <>
      <div className="lgnOuterDiv" style={{ backgroundColor: "#FFFF" }}>
        <div className="lgnFormDiv">
          <div className="RegLogoDiv">
            {/* <img
              src={logoImageUrl ?? ''}
              onClick={() => setScreen(1)} /> */}
          </div>
          <div className="loginH1">
            {/* <h1 style={{color:"#007749"}}>ShareResume.co</h1> */}
            <h1>Log in to your Account</h1>
          </div>
          {screen != 4 && (
            <div className="loginH1">
              <span
                style={{
                  color:
                    forgotPwd == true && screen == 3
                      ? "#403F3F"
                      : screen == 3
                        ? "#BC0000"
                        : "#403F3F",
                }}
              >
                {forgotPwd == true && screen == 3
                  ? "Reset your password"
                  : screen == 3
                    ? "Your password is not set please set the password"
                    : "Please login to website!"}
              </span>
            </div>
          )}
          {screen != 4 && (
            <div className="RegFieldDiv lgnMtop">
              <label>
                Mobile No
                <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
              </label>
              <div className="RegMnoDiv">
                <select
                  // value={lgnDetails?.countryCodeId}
                  onChange={(e) => handleLgnInputChange(e, "cntryCode")}
                >
                  {countryCodes.map((item) => {
                    return (
                      <option
                        value={JSON.stringify(item)}
                        selected={item?.countryCodeId === lgnDetails?.countryCodeId}
                      >
                        +{item?.countryCode}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="number"
                  onFocus={(e) => {
                    e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })
                  }}
                  inputMode="numeric"
                  className="focusClass"
                  value={lgnDetails?.mno}
                  onKeyDown={(e) => {
                    if (e.which === 38 || e.which === 40) {
                      e.preventDefault();
                      return
                    }
                    handleKeyPressed(e, "mobileNoVerification")
                  }}
                  onChange={(e) => handleLgnInputChange(e, "mno")}
                  placeholder="Enter your mobile number"
                />
              </div>
              <small className="RegErrMsg">{errMsg?.mno}</small>
            </div>
          )}
          {screen == 1 ? (
            <div className="RegBtnDiv lgnWidth">
              <button onClick={handleMobileNoVerification}>Next</button>
              <button className='' style={{ margin: "5px 0" }} name='submit' onClick={handleNavigateDashoard}>Back</button>
            </div>
          ) : screen == 2 ? (
            <div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  onKeyDown={(e) => handleKeyPressed(e, "login")}
                  type={hidePwd?.pwd == true ? "text" : "password"}
                  value={lgnDetails?.pwd}
                  className="RegInput focusClass"
                  placeholder="Enter your password"
                  onChange={(e) => handleLgnInputChange(e, "pwd")}
                />
                <small className="RegErrMsg">{errMsg?.pwd}</small>
                <img
                  className="eyeIcon"
                  style={{ top: errMsg?.pwd == "" ? "50%" : "40%" }}
                  onClick={handlePwdShow}
                  src={hidePwd.pwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div className="RegResendOtpDiv fieldWidth">
                <span onClick={handleForgotPwd}>Forgot Password?</span>
              </div>
              <div className="RegBtnDiv lgnWidth" style={{ marginTop: "3%" }}>
                <button onClick={handlePosterLogin}>Login</button>
              </div>
            </div>
          ) : screen == 3 ? (
            <div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Enter New Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  type={hidePwd.npwd == false ? "password" : "text"}
                  value={lgnDetails?.npwd}
                  className="RegInput focusClass"
                  placeholder="Enter your password"
                  onChange={(e) => handleLgnInputChange(e, "npwd")}
                />
                <small className="RegErrMsg">{errMsg?.npwd}</small>
                <img
                  className="eyeIcon"
                  style={{ top: errMsg?.npwd == "" ? "50%" : "40%" }}
                  onClick={handleNpwdShow}
                  src={hidePwd.npwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Confirm Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  type={hidePwd.cnpwd == false ? "password" : "text"}
                  value={lgnDetails?.cnpwd}
                  className="RegInput focusClass"
                  placeholder="Enter your password"
                  onChange={(e) => handleLgnInputChange(e, "cnpwd")}
                />
                <small className="RegErrMsg">{errMsg?.cnpwd}</small>
                <img
                  className="eyeIcon"
                  style={{ top: errMsg?.cnpwd == "" ? "50%" : "40%" }}
                  onClick={handleCnpwdShow}
                  src={hidePwd.cnpwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div className="RegBtnDiv lgnWidth">
                <button onClick={handleOtpSend}>Next</button>
              </div>
            </div>
          ) : screen == 4 ? (
            <div>
              <div className="lgnOtpImgDiv">
                <img
                  src={mobileImg}
                />
              </div>
              <div className="lgnOtpDivTxt">
                We have sent OTP on your registered mobile number
              </div>
              <div className="lgnOtpDiv">
                <label>
                  Please enter the OTP
                  <small style={{ color: "#BC0000" }}>*</small>
                </label>
                <OtpInput
                  value={otp2}
                  onChange={setOtp2}
                  numInputs={4}
                  renderSeparator={<span style={{ width: "1.5vw" }}></span>}
                  renderInput={(props) => <input {...props} onKeyDown={e => { if (e.which === 38 || e.which === 40) e.preventDefault() }} />}
                  inputStyle="regOTPinputField"
                  inputType="number"
                  onFocus={(e) => {
                    e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })
                  }}
                />
              </div>
              <div className="noOtpTxt">
                Didn't receive a OTP?{" "}
                <span style={{ cursor: "pointer" }} onClick={handleResend}>
                  Resend OTP
                </span>
              </div>
              <div className="setPwdBtnDiv">
                <button className="setPwdBtn" onClick={handleSetPwd}>
                  Set Password
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* PortalUserRole=="ROLE_MANAGER" */ false ? (
            <div className="LgnNoAcc">
              ShareResume.co powered by&nbsp;
              <span onClick={() => window.open('https://quikhire.ai/', '_blank')}>QuikHire.ai</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="lgnImgDiv">
          <div className="OverlayNew">
            <div className="RegImgDivTxt LgnImgDivTxt">
              <div className="HiringMore">Craft Your Perfect Resume</div>
              <div className="HiringMore" style={{ fontWeight: 500, padding: '4px 0px' }}>Stand Out from the crowd.</div>
              <p>
                Welcome to QuikHire.ai resume builder, where we enable job seekers to showcase their
                skills and land their dream job. Our innovative resume creator guides you
                through the process, of building a professional and impactful resume
                that gets noticed by recruiters.
                <br />
                Join quikhire.ai and take control of your job search!
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={() => setSuccessModalIsOpen(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setSuccessModalIsOpen(false)} className="delAccModalCrossPara">
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{successMsg}</div>
        <div className="modalOkBtnDiv">
          <button className="modalOkBtn" onClick={() => setSuccessModalIsOpen(false)}>
            OK
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={() => setModal(false)}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg?.includes('number is not registered') ?
            <>
             <p className="errorMessageP"> This number is not registered with Shareresume.co, </p> <p className="errorMessageP"> <span onClick={() => window.open(ResumeURL, '_blank')} style={{ color: "blue", cursor: 'pointer',textDecoration : 'underline' }}> Create a Resume </span> first, before registration. </p>
            </>
            : <span> {popupMsg} </span>
          }
        </div>
        <div className="okBtnModal">
          <button onClick={() => setModal(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p
              onClick={() => {
                navigate("/Main/*");
                setSuccessPopup(false);
                setScreen(2);
              }}
              className="delAccModalCrossPara"
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{popupMsg}</div>
        <div className="okBtnModal">
          <button onClick={handleAutoLogin}>OK</button>
        </div>
      </Modal>
    </>
  );
};

export default Login;
