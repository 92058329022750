import React, { useEffect,useState } from 'react'
import './LoaderModal.css'
import ReactModal from "react-modal";
import Lottie from "lottie-react";
import loaderJson from "../../Assets/Loader.json"
const LoaderModal = (props) => {
    const customStyles = {
        content: {
            width: "370px",
            height: "auto",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: "4px",
            overflow: 'hidden',
            padding:'5px 0 0 0',
            border:"none",
            background:"transparent"
            
            
        },
    };
    const style = {
        width: "45%"
    };

    useEffect(()=>{
        props.setLoader==true?
        LoaderOpen():LoaderClose()
    },[props.setLoader]) 
    const[loader, setLoader]=useState({
        isOpen:false,
        onRequestClose:""

    })
     const LoaderClose=()=>{
        setLoader({
            isOpen:false,
            onRequestClose:true
    
        })
    }
     const LoaderOpen=()=>{
        setLoader({
            isOpen:true,
            onRequestClose:""
        })
    }
    return (
        <ReactModal ariaHideApp={false}
            isOpen={loader.isOpen}
            contentLabel="Minimal Modal Example"
            className={props.class}
            overlayClassName="LoaderOverlay"
            onRequestClose={loader.onRequestClose}
            style={customStyles}
        >
            <div className="MsgDiv">
            <Lottie animationData={loaderJson} style={style} />
            <span className='loaderMsg'>Please wait, we are processing your data...</span>
            </div>
        </ReactModal>
    )
}

export default LoaderModal;