import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom'
import Navigation from './Navigation/Navigation';
import WebNavigation from './WebNavigation/WebNavigation';
import Login from './Login/Login';
import LandingPage from './Screens/LandingPage/LandingPage';
// import ResumeView from './ResumeView';
import ResumeViewPage from './Screens/ResumeViewPage/ResumeViewPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Navigate to='Dashboard' />} className="test" />
        <Route path='/Main/*' element={window.screen.width > 1023 ? <WebNavigation /> : <Navigation />} />
        <Route path='/Dashboard' element={<LandingPage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/:userId' element={<ResumeViewPage />} />
      </Routes>
    </div>
  );
}

export default App;
