import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './EducationalDetails.css';
import { ConstantScreen } from '../../ConstantsScreen/ConstantScreen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Lottie from 'lottie-react';
import Error from '../../Assets/Opps.json'
import Success from '../../Assets/Success.json'
import deleteIcon from '../../Assets/delete.png'
import moment from 'moment';

const EducationalDetails = ({ data, setData, educationErrors, setEducationErrors, setSubmitResume }) => {
    const [errModal, setErrModal] = useState(false)
    const [successModal, setsuccessModal] = useState(false)
    const [modalMsg, setModalMsg] = useState("")

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            minWidth: window.screen.width > 1023 ? "30%" : "70%",
            maxWidth: window.screen.width > 1023 ? "50%" : "80%",
            borderRadius: "10px",
            padding: "10px"
        },
    };
    const handleAddEducationalDet = () => {
        if (data.educations.length < 3) {
            setData({
                ...data,
                educations: [
                    ...data.educations,
                    { degree: '', university: '', startYear: '', endYear: '' }
                ]
            });
        } else {
            // toast.warning("You can add upto 3 educations.");
            setModalMsg("You can add upto 3 educations.")
            setErrModal(true)
        }
    };

    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const year = date.getFullYear();
    //     return `${year}`;
    // };
    const handleChange = (index, field, value) => {
        setSubmitResume(false)
        const newEducationDets = [...data.educations];
        if (field == 'startYear' || field == 'endYear') {
            if (field == "startYear") {
                newEducationDets[index]['endYear'] = null
            }
            newEducationDets[index][field] = moment(value).format('YYYY');
            setData({ ...data, educations: newEducationDets });
        } else {
            newEducationDets[index][field] = value;
            setData({ ...data, educations: newEducationDets });
        }
        const errorMsg = validateField(field, value);
        setEducationErrors((prevErrors) => {
            // Ensure prevErrors is an array
            const updatedErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
            if (!updatedErrors[index]) {
                updatedErrors[index] = {};
            }
            updatedErrors[index][field] = errorMsg;
            return updatedErrors;
        });
    };
    const validateField = (field, value) => {
        let errorMsg = '';
        const startsWithLetterRegex = /^[A-Za-z][A-Za-z\s]*$/;
        switch (field) {
            case 'degree':
                if (!value.trim()) {
                    errorMsg = 'Degree Name cannot be empty.';
                }
                break;
            case 'university':
                if (!value.trim()) {
                    errorMsg = 'College Name cannot be empty.';
                } else if (!startsWithLetterRegex?.test(value)) {
                    errorMsg = 'College Name should contain only alphabets.';
                }
                break;
            case 'startYear':
                if (value == "") {
                    errorMsg = 'Start Year cannot be empty.';
                }
                break;
            case 'endYear':
                if (!value) {
                    errorMsg = 'End Year cannot be empty.';
                }
                break;
            default:
                break;
        }

        return errorMsg;
    };
    const handleRemoveEducation = (indexToRemove) => {
        if (data.educations.length > 1) {
            if (indexToRemove >= 0 && indexToRemove < data.educations.length) {
                const newEducations = data.educations.slice();
                newEducations.splice(indexToRemove, 1);
                setData({ ...data, educations: newEducations });
            } else {
                console.error('Invalid index for removing Education:', indexToRemove);
            }
        } else {
            // toast.warning("Please add atleast one Education before removing.");
            setModalMsg("Please add atleast one Education before removing.")
            setErrModal(true)
        }
    };
    return (
        <div className="EducationalDetform">
            {data?.educations?.map((educationItem, index) => (
                <div className="EducationalDetFormFields" key={index}>
                    <div className='experinceHeadingDetails'>
                        <span className='experienceCount'>Education {index + 1}</span>
                        <button type="button" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => { handleRemoveEducation(index) }} className="removeFieldButton"><img className='deleteField' style={{ width: "0.8rem" }} src={deleteIcon} alt='something went wrong' /></button>
                    </div>
                    <div className='webRespFieldsWrapper'>
                        <ConstantScreen.InputText
                            labelTxt='Degree Name'
                            inputType='text'
                            inputId={`degreeName-${index}`}
                            inputClass='degreeNameInput'
                            inputStyle={{ borderRadius: '5px' }}
                            inputValue={educationItem.degree}
                            onChange={(e) => handleChange(index, 'degree', e.target.value)}
                            inputWrapperStyle='degreeNameWrapper'
                            errMsg={educationErrors[index]?.degree || ""}
                            placeholderTxt='Enter Degree Name'
                            isRequired={true}
                        />
                        <ConstantScreen.InputText
                            labelTxt='College Name'
                            inputType='text'
                            inputId={`collegeName-${index}`}
                            inputClass='collegeNameInput'
                            inputStyle={{ borderRadius: '5px' }}
                            inputValue={educationItem.university}
                            onChange={(e) => handleChange(index, 'university', e.target.value)}
                            inputWrapperStyle='collegeNameWrapper'
                            errMsg={educationErrors[index]?.university || ""}
                            placeholderTxt='Enter College Name'
                            isRequired={true}
                        />
                        <ConstantScreen.DatePickerField
                            labelTxt='From'
                            inputId={`startDate-${index}`}
                            inputClass='startDateInput'
                            inputStyle={{ borderRadius: '5px' }}
                            selectedValue={educationItem.startYear}
                            onChange={(date) => handleChange(index, 'startYear', date)}
                            inputWrapperStyle='collegeNameWrapper'
                            errMsg={educationErrors[index]?.startYear || ""}
                            showMonthYearPicker={false}
                            showYearPicker={true}
                            dateFormat={"yyyy"}
                            maxDate={new Date()}
                            placeholderTxt='Enter From Date'
                            isRequired={true}
                        />
                        <ConstantScreen.DatePickerField
                            labelTxt='To'
                            inputId={`endDate-${index}`}
                            inputClass='endDateInput'
                            inputStyle={{ borderRadius: '5px' }}
                            selectedValue={educationItem.endYear}
                            onChange={(date) => handleChange(index, 'endYear', date)}
                            inputWrapperStyle='collegeNameWrapper'
                            errMsg={educationErrors[index]?.endYear || ""}
                            showMonthYearPicker={false}
                            showYearPicker={true}
                            dateFormat={"yyyy"}
                            maxDate={new Date()}
                            minDate={educationItem.startYear || ""}
                            placeholderTxt='Enter To Date'
                            isRequired={true}
                        />
                    </div>
                </div>
            ))}
            <ConstantScreen.AddField
                addFieldWrapper='fieldButton'
                fieldTxt='Add Education'
                onClick={handleAddEducationalDet}
            />
            <Modal
                isOpen={errModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setErrModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
            >
                <div className='modalCloseDiv'><span onClick={() => setErrModal(false)}>X</span></div>
                <div className='modalLottieDiv'>
                    <Lottie className='lottieFile' animationData={Error} loop={true} />
                </div>
                <div className='modalMsg'>{modalMsg}</div>
                <button className='modalOkBtn' onClick={() => setErrModal(false)}>OK</button>
            </Modal>

            <Modal
                isOpen={successModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setsuccessModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
            >
                <div className='modalCloseDiv'><span onClick={() => setsuccessModal(false)}>X</span></div>
                <div className='modalLottieDiv'>
                    <Lottie className='lottieFile' animationData={Success} loop={true} />
                </div>
                <div className='modalMsg'>{modalMsg}</div>
                <button className='modalOkBtn' onClick={() => setsuccessModal(false)}>OK</button>
            </Modal>
        </div>
    );
};
<ToastContainer autoClose={4000} />

EducationalDetails.propTypes = {
    data: PropTypes.shape({
        educations: PropTypes.arrayOf(
            PropTypes.shape({
                degreeName: PropTypes.string,
                collageName: PropTypes.string,
                startDate: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.instanceOf(Date)
                ]),
                endDate: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.instanceOf(Date)
                ])
            })
        )
    }).isRequired,
    setData: PropTypes.func.isRequired
};

export default EducationalDetails;
