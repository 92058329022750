export const ApiProfile = {
    Dev: 'http://localhost:8080/',
    Test: 'http://13.52.157.11:8080/resume-builder/api/v1/',
    UAT: '',
    Prod: 'https://api.shareresume.co/api/v1/'
}
export const webUrlLink = {
    Dev: 'http://localhost:3000/#/',
    Test: 'http://13.52.157.11:8080/resume_builder_int/#/',
    Uat: '',
    Prod: 'https://www.shareresume.co/#/',
}
export const getActivProfile = (profile) => {
    switch (profile) {
        case 'Dev': return (ApiProfile.Dev);
        case 'Test': return (ApiProfile.Test);
        case 'UAT': return (ApiProfile.UAT);
        case 'Prod': return (ApiProfile.Prod);
        default: return (ApiProfile.Dev);
    }
}
