import React, { useEffect, useState } from 'react';
import { ConstantScreen } from '../../ConstantsScreen/ConstantScreen';
import './PersonalDetails.css';
import { getAPICall } from '../../APIMethods/APIMethods';
import { Url } from '../../Constants/ApiUrlConstants';
import Modal from 'react-modal';
import Lottie from 'lottie-react';
import Error from '../../Assets/Opps.json'
const PersonalDetails = ({ data, setData, citySelectedValue,setBase64Img,getURLSections, setAudioFile, allResumeDetails, skillSelectedValues, errors, setErrors, setSubmitResume }) => {
  const [skillOptions, setSkillOptions] = useState([]);
  const [cityAndStateOptions, setCityAndStateOptions] = useState([]);
  const [cntryCodeOptions, setCntryCodeOptions] = useState([])
  const [selectedCntryCode, setSelectedCntryCode] = useState({})
  const [errPersonalModal, setErrPersonalModal] = useState(false)
  const [modalPersonalMsg, setModalPersonalMsg] = useState("")

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: "30%",
      maxWidth: "50%",
      borderRadius: "10px",
      padding: "10px"
    },
  };
  useEffect(() => {
    getAPICall(Url.getAllSkills)
      .then((res) => {
        const updatedSkills = res?.DATA.map((item) => ({
          value: item.skillId,
          label: item.name,
        }));
        setSkillOptions(updatedSkills)
      })
    getAPICall(Url?.getCountryCode)
      .then((res) => {
        setCntryCodeOptions(res?.DATA)
        setSelectedCntryCode(res?.DATA[0])
      })
  }, [])
  useEffect(() => {
    getAPICall(Url.getAllCities?.replace('{countryCodeId}', selectedCntryCode?.countryCodeId ? selectedCntryCode?.countryCodeId : 1))
      .then((res) => {
        const updatedCities = res?.DATA.map((item) => ({
          value: item.cityId,
          label: item.cityName,
          state: item.state.stateName
        }));
        setCityAndStateOptions(updatedCities)
      })
  }, [selectedCntryCode])

  useEffect(() => {
    window.screen.width > 1023 && setData({ ...data, city: { cityId: '', cityName: '', state: { stateId: null, stateName: "" } }, state: "" })
  }, [selectedCntryCode])

  const handleInputChange = (e, field) => {
    setSubmitResume(false)
    const value = field === 'skill' || field === 'city' ? e :
      field === 'experienceStatus' ? e?.target?.checked :
        e?.target?.value ?? e;
    setData((prevData) => {
      let updatedData = { ...prevData };

      if (field === 'mobileNumber') {
        const sanitizedMobileNumber = value.replace(/\D/g, '').trim();
        if (sanitizedMobileNumber.length <= 10) {
          updatedData[field] = sanitizedMobileNumber;
          updatedData['countryCode'] = selectedCntryCode
        }
        if (sanitizedMobileNumber.length >= 10) {
          ValidateMobileNumber(sanitizedMobileNumber)
        }
      } else if (field === 'totalExperience') {
        const sanitizedExp = value.replace(/[^\d.]/g, '').trim()
        if (sanitizedExp.length <= 5) {
          updatedData[field] = sanitizedExp;
        }
      } else if (field === 'skill') {
        const updatedSkills = value.map((item) => ({
          skillId: typeof item.value === 'string' ? undefined : item.value,
          name: item.label
        }));
        updatedData.skills = updatedSkills;
      } else if (field === 'city') {
        updatedData.city = {
          cityId: value.value,
          cityName: value.label,
        };
        updatedData.state = value.state;
      } else if (field === 'experienceStatus') {
        updatedData[field] = value;
        const interHas = allResumeDetails?.internShipDetails?.internships?.every(item => !item.designation || !item.designation.designationName || !item?.companyName)
        if (interHas) {
          updatedData['isIntern'] = !value
        }
        if (!value) {
          updatedData.totalExperience = '';
        }
      } else if (field === 'profilePic') {
        setBase64Img('')
        document.getElementById('profilePic').value = ""
        // console.log('>>>>>>>>>>>', profilePicValue)
        const file = e;
        if (file) {
          const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
          if (fileSizeInMB > 2) {
            setModalPersonalMsg('The profile image should be 2 MB or smaller')
            setErrPersonalModal(true)
            // alert('File size exceeds 2MB. Please upload a smaller image.');
          } else if(!file.type.startsWith('image/')) {
            setModalPersonalMsg('The file must be in image (.jpg, .jpeg, .png) format.')
            setErrPersonalModal(true)
          }else{
            updatedData["profileUrl"] = file            
          }
        } else {
          // Process the file further or upload it
          updatedData["profileUrl"] = file          
        }

      } else if (field === 'audioFile') {
        setAudioFile('')
        document.getElementById('audioFile').value = ""
        const file = e;
        if (file) {
          const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
          if (fileSizeInMB > 2) {
            setModalPersonalMsg('The audio file should be 2 MB or smaller.')
            setErrPersonalModal(true)
          } else if(!file.type.startsWith('audio/')) {
            setModalPersonalMsg('The file must be in audio (.mp3, .m4a) format')
            setErrPersonalModal(true)
          } else {
            const fileURL = URL.createObjectURL(file);
            setAudioFile(fileURL)
            updatedData['summaryAudio'] = file
          }
        }else {
          // Process the file further or upload it
          updatedData["summaryAudio"] = file
        }
        
      } else if (field == "gender") {
        updatedData['gender'] = e?.toLowerCase()
      } else {
        updatedData[field] = value;
      }

      return updatedData;
    });

    const errorMsg = validateField(field, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMsg
    }));
  };

  const validateField = (field, value) => {
    let errorMsg = '';
    switch (field) {
      case 'firstName':
      case 'middleName':
      case 'lastName':
        if (value && !/^[A-Za-z\s]+$/.test(value)) {
          errorMsg = 'Full Name must contain only letters and spaces.';
        }
        break;
      case 'totalExperience':
        const sanitizedExp = value.replace(/\D/g, '').trim();
        if (!sanitizedExp) {
          errorMsg = 'Total Experience is required and should contain digits only.';
        } 
        break;
      case 'mobileNumber':
        const sanitizedMobileNumber = value.replace(/\D/g, '').trim();
        if (!sanitizedMobileNumber) {
          errorMsg = 'Mobile Number is required and should contain digits only.';
        }
        //  else if (!mobileNumberRegex.test(sanitizedMobileNumber)) {
        //   errorMsg = 'Mobile Number must be a 10-digit number containing only digits.';
        // }
        break;
      case 'email':
        if (!/^(?!.*\.\.)(?=[a-zA-Z]+[a-zA-Z0-9._%+-]*@)[a-zA-Z0-9._%+-]+@[a-zA-Z.-]{1,5}[a-zA-Z][a-zA-Z.-]*\.[a-zA-Z]{2,}$/.test(value) || value.length >= 50) {
          errorMsg = 'Invalid email address.';
        }else{
          ValidateEmailId(value)
        }
        break;
      case 'city':
        if (!value) {
          errorMsg = 'City cannot be empty.';
        }
        break;
      case 'state':
        if (!value.trim()) {
          errorMsg = 'State cannot be empty.';
        }
        break;
      case 'skill':
        if (!value || value.length === 0) {
          errorMsg = 'Skills cannot be empty.';
        }
        break;
    }

    return errorMsg;
  };

  const ValidateMobileNumber = (mobileNumber) => {
    const userData = JSON.parse(sessionStorage.getItem('user'))?.firstName ? JSON.parse(sessionStorage.getItem('user')) : { mobileNumber: '', countryCode: { countryCodeId: '' } };
    if (userData?.mobileNumber != mobileNumber || userData?.countryCode?.countryCodeId != selectedCntryCode?.countryCodeId) {
      getAPICall(Url.mobileNoVerification.replace("{countrycode}", selectedCntryCode?.countryCodeId).replace("{mobileNumber}", mobileNumber))
        .then((res) => {
          if (res?.SUCCESS) {
            // userExists
            setErrors((prevErrors) => ({
              ...prevErrors,
              ['mobileNumber']: 'This mobile number is already registered.'
            }));
          } else {
            // errors.mobileNumber = ''
          }
        })
        .catch((e) => console.log(e));
    }
  }

  const ValidateEmailId = (emailId) => {
    const userData = JSON.parse(sessionStorage.getItem('user'))?.firstName ? JSON.parse(sessionStorage.getItem('user')) : { email:'' };
    if (userData?.email != emailId) {
      getAPICall(Url.emailVerification.replace("{email}", emailId))
        .then((res) => {
          console.log('>>>>>>>>>>>', res)
          if (res?.DATA) {
            // userExists
            setErrors((prevErrors) => ({
              ...prevErrors,
              ['email']: 'This email id is already registered.'
            }));
          } else {
            // errors.mobileNumber = ''
          }
        })
        .catch((e) => console.log(e));
    }
  }
  return (
    <div className="personalDetailsForm">
      <ConstantScreen.InputText
        labelTxt="Full Name"
        inputType="text"
        inputId="fullName"
        inputClass="fullNameInput"
        inputStyle={{ borderRadius: '5px' }}
        inputValue={data?.firstName}
        onChange={(e) => handleInputChange(e, 'firstName')}
        inputWrapperStyle="fullNameWrapper"
        errMsg={errors?.firstName}
        placeholderTxt="Enter Full Name"
        isRequired={true}
        maxLength={50}
      />
      <ConstantScreen.MobileNumberField
        labelTxt="Mobile No."
        inputId="mobileNo"
        inputClass="mobileNoInputNew"
        inputStyle={{ borderRadius: '5px' }}
        inputValue={data?.mobileNumber}
        onChange={(e) => handleInputChange(e, 'mobileNumber')}
        inputWrapperStyle={`mobileNoWrapper ${getURLSections?.userAction == "resumeEdit" ? "hideMobile" :""}`}
        errMsg={errors?.mobileNumber}
        placeholderTxt="Enter Mobile Number"
        isRequired={true}
        maxLength={10}
        cntryCodeOptions={cntryCodeOptions}
        setCntryCodeOptions={setCntryCodeOptions}
        selectedCntryCode={selectedCntryCode}
        setSelectedCntryCode={setSelectedCntryCode}
        data={data}
        setData={setData}
      />
      <ConstantScreen.InputFile
        labelTxt="Profile Photo"
        inputType="file"
        inputId="profilePic"
        inputClass="mobileNoInput"
        inputStyle={{ borderRadius: '5px' }}
        inputValue={data?.profileUrl}
        onChange={(e) => handleInputChange(e, 'profilePic')}
        inputWrapperStyle="emailIdWrapper"
        errMsg={errors?.profileUrl}
        placeholderTxt="Select Profile Photo"
        acceptInput={".jpg, .jpeg, .png, .heic"}        
        isRequired={false}
        s3BucketFileName = {data?.profileImageName ?? ''}
      />
      <ConstantScreen.InputFile
        labelTxt="Upload Audio"
        inputType="file"
        inputId="audioFile"
        inputClass="mobileNoInput"
        inputStyle={{ borderRadius: '5px' }}
        inputValue={data?.summaryAudio}
        onChange={(e) => handleInputChange(e, 'audioFile')}
        inputWrapperStyle="emailIdWrapper"
        errMsg={errors?.summaryAudio}
        placeholderTxt="Upload Audio File"
        acceptInput={"audio/mp3"}
        isRequired={false}
        s3BucketFileName = {data?.summaryAudioName ?? ''}
      />
      <ConstantScreen.InputText
        labelTxt="Email ID"
        inputType="text"
        inputId="emailId"
        inputClass="emailIdInput"
        inputStyle={{ borderRadius: '5px' }}
        inputValue={data?.email}
        onChange={(e) => handleInputChange(e, 'email')}
        inputWrapperStyle="emailIdWrapper"
        errMsg={errors?.email}
        placeholderTxt="Enter Email ID"
        isRequired={true}
      />
      <ConstantScreen.ToggleSwitch
        key={data?.experienceStatus}
        label="Experience/Fresher"
        inputValue={data?.experienceStatus}
        onChange={(e) => handleInputChange(e, 'experienceStatus')} />
      {data.experienceStatus &&
        <ConstantScreen.InputText
          labelTxt="Total Experience (In Years)"
          inputType="text"
          inputId="totalExperience"
          inputClass="totalExperienceInput"
          inputStyle={{ borderRadius: '5px' }}
          errorMsg={errors.totalExperience}
          inputValue={data?.totalExperience}
          onChange={(e) => handleInputChange(e, 'totalExperience')}
          inputWrapperStyle="totalExperienceWrapper"
          errMsg={errors?.totalExperience}
          placeholderTxt="Ex. 3.2 /0.6 /1"
          isRequired={true}
        />}
      {/* <ConstantScreen.InputText
        labelTxt="Address"
        inputType="text"
        inputId="address"
        inputClass="addressInput"
        inputStyle={{ borderRadius: '5px' }}
        inputValue={data.address}
        onChange={(e) => handleInputChange(e, 'address')}
        inputWrapperStyle="addressWrapper"
        errMsg=""
        placeholderTxt="Enter your Address"
        isRequired={true}
      /> */}
      <div className='totalExperienceWrapper genderWrapper'>
        <label>Select Gender<span className='inputLable' style={{ color: "#BC0000" }}>*</span></label>
        <div style={{ display: "flex", gap: "20px", marginTop: "15px" }}>
          <span style={{ display: "flex", alignItems: "center", gap: "2px" }}><input name='gender' type='radio' id='maleRadio' checked={data?.gender?.toUpperCase() == "MALE"} onChange={(e) => handleInputChange('MALE', 'gender')} style={{cursor:"pointer"}}/><label htmlFor='maleRadio'> Male</label></span>
          <span style={{ display: "flex", alignItems: "center", gap: "2px" }}><input name='gender' type='radio' id='femaleRadio' checked={data?.gender?.toUpperCase() == "FEMALE"} onChange={(e) => handleInputChange('FEMALE', 'gender')} style={{cursor:"pointer"}}/><label htmlFor='femaleRadio'> Female</label></span>
          <span style={{ display: "flex", alignItems: "center", gap: "2px" }}><input name='gender' type='radio'  id='otherRadio' checked={data?.gender?.toUpperCase() == "OTHER"} onChange={(e) => handleInputChange('OTHER', 'gender')} style={{cursor:"pointer"}}/><label htmlFor='otherRadio'> Other</label></span>
        </div>
        {!!errors?.gender && <small style={{display :'block', marginTop : '5px'}} className='errorMessageShow'>{errors?.gender}</small>}
      </div>
      <ConstantScreen.SelectField
        labelTxt="City"
        inputId="city"
        onChange={(e) => handleInputChange(e, 'city')}
        inputWrapperStyle="cityWrapper"
        selectOptions={cityAndStateOptions}
        errMsg={errors?.city}
        isMultiValue={false}
        isCreatable={false}
        isRequired={true}
        menuPlacement={"top"}
        placeholderTxt={"Select City"}
        selectedValue={Object.keys(citySelectedValue).length !== 0 ? citySelectedValue : "Select"}
      />
      <ConstantScreen.InputText
        labelTxt="State"
        inputType="text"
        inputId="state"
        inputClass="stateInput"
        inputStyle={{ borderRadius: '5px' }}
        inputValue={data?.state}
        onChange={(e) => handleInputChange(e, 'state')}
        inputWrapperStyle="stateWrapper"
        errMsg={errors?.state}
        readOnly={true}
        placeholderTxt="Enter your State"
        isRequired={true}
      />
      <ConstantScreen.SelectField
        labelTxt="Skills"
        inputId="zipCode"
        selectedValue={skillSelectedValues}
        onChange={(e) => handleInputChange(e, 'skill')}
        inputWrapperStyle="zipCodeWrapper"
        placeholderTxt={"Select Skills"}
        selectOptions={skillOptions}
        errMsg={errors?.skill}
        isMultiValue={true}
        menuPlacement={"top"}
        isCreatable={true}
        isRequired={true}
      />
      <Modal
        isOpen={errPersonalModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setErrPersonalModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className='modalCloseDiv'><span onClick={() => setErrPersonalModal(false)}>X</span></div>
        <div className='modalLottieDiv'>
          <Lottie className='lottieFile' animationData={Error} loop={true} />
        </div>
        <div className='modalPersonalMsg' style={{textAlign : 'center'}}>{modalPersonalMsg}</div>
        <button className='modalOkBtn' onClick={() => setErrPersonalModal(false)}>OK</button>
      </Modal>
    </div>
  );
};

export default PersonalDetails;
