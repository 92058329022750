import React, { useEffect, useState } from 'react'
import { ConstantScreen } from '../../ConstantsScreen/ConstantScreen';
import { getAPICall } from '../../APIMethods/APIMethods';
import { Url } from '../../Constants/ApiUrlConstants';
import './ResumeViewPage.css'
import { useNavigate } from 'react-router-dom';
const ResumeViewPage = () => {
    const navigate = useNavigate()
    const [audioFile, setAudioFile] = useState('')
    const [base64Img, setBase64Img] = useState('')
    const [citySelectedValue, setCitySelectedValue] = useState({})
    const [allResumeDetails, setAllResumeDetails] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const userIDARRAY = window.location.href.split('/');
        const userID = userIDARRAY[userIDARRAY.length - 1]
        try {
            if (userID) {
                setLoader(true)
                getAPICall(Url.getResumeByUserID?.replace('{userUid}', userID))
                    .then((res) => {
                        setLoader(false)
                        const responseData = res.DATA;
                        const cityData = responseData?.city || {};

                        const updatedCity = {
                            value: cityData?.cityId,
                            label: cityData?.cityName,
                            state: cityData?.state,
                        }

                        setCitySelectedValue(updatedCity)
                        setAllResumeDetails(res.DATA);
                        setBase64Img(responseData?.base64ImageUrl)
                        setAudioFile(responseData?.summaryAudio)
                    })
                    .catch((error) => {
                        setLoader(false)
                        console.error('API call error:', error);
                    });
            }
        } catch (error) {
            console.error('Decryption error:', error);
        }
    }, []);
    const navigateToLogin = () =>{
        navigate("/login")
    }
    return ( 
        <div className='resumeViewShow' style={{ width: "70%"}}>
            <span className='modifyBtn'>Click <span className='HereBtn' onClick={navigateToLogin}>Here</span> to Modify</span>
        <div className='webPreviewDiv' style={{ width:"100%", height: "90vh", background : "#fff", margin:'10px auto' }}>
            <ConstantScreen.MobileResumePreview audioFile={audioFile} citySelectedValue={citySelectedValue} userAction={"resumeViewPage"} allResumeDetails={allResumeDetails} base64Img={base64Img} />
            <ConstantScreen.LoaderModal setLoader={loader} />
        </div> 
        </div>
    )
}

export default ResumeViewPage