import React, { createContext, useState } from 'react'

const UserContext1 = createContext()

const UserContext = ({children}) => {
  const [hideAudioPreview, setHideAudioPreview] = useState(false);
  return (
    <UserContext1.Provider value={{ hideAudioPreview, setHideAudioPreview }}>
        {children}
    </UserContext1.Provider>
  )
}

export {UserContext,UserContext1}